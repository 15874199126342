import React, { SVGProps } from 'react'

// Styles
import style from './Sleeping-Arrangements.module.scss'

// Icons
import King from 'assets/icons/icon-kingBed.svg'
import CaliforniaKing from 'assets/icons/icon-californiaKingBed.svg'
import Queen from 'assets/icons/icon-queenBed.svg'
import Full from 'assets/icons/icon-fullBed.svg'
import Twin from 'assets/icons/icon-twinBed.svg'
import Pullout from 'assets/icons/icon-pullout.svg'
import FivePlusBeds from 'assets/icons/icon-fivePlusBeds.svg'

// Types
import { BoomiListing } from 'types/externalData'

type Bed = BoomiListing['bedrooms']['beds']

type BedDisplay = {
  order: number
  svg: React.FC<SVGProps<SVGSVGElement>> | null
  displayName?: string
  suffix?: string
}

type BedroomProps = {
  bedroom: BoomiListing['bedrooms']
}

const Bedroom: React.FC<BedroomProps> = ({ bedroom }) => {
  const { beds, name, floor } = bedroom

  const bedsData: {
    [key: string]: BedDisplay
  } = {
    CALIFORNIA_KING: {
      order: 0,
      svg: CaliforniaKing,
      displayName: 'California King',
    },
    KING: { order: 1, svg: King },
    QUEEN: { order: 2, svg: Queen },
    FULL: { order: 3, svg: Full },
    TWIN: { order: 4, svg: Twin },
    PULL_OUT: {
      order: 5,
      svg: Pullout,
      displayName: 'Pull-Out',
      suffix: 'couch',
    },
    default: { order: Infinity, svg: null },
  }

  // Remove 0 number beds and put beds in order of preference
  const orderedBeds = beds
    .filter((bed: Bed) => bed.number > 0)
    .sort((a: Bed, b: Bed) => {
      return (
        (bedsData[a.type].order || bedsData.default.order) -
        (bedsData[b.type].order || bedsData.default.order)
      )
    })

  // Sum all bed.numbers
  const totalNumberOfBeds = beds
    .map((bed: Bed) => bed.number)
    .reduce((a: number, b: number) => a + b, 0)

  const getBedNames = (i: number, numberOfBeds: number, bedName: string) => {
    let bedNames = ''

    const suffix = bedsData[bedName].suffix ? bedsData[bedName].suffix : 'bed'

    const formattedName = bedsData[bedName].displayName
      ? bedsData[bedName].displayName
      : bedName.toLowerCase()

    // Multiple beds - display number of beds and plural of bed type
    if (totalNumberOfBeds > 1) {
      bedNames += `${numberOfBeds > 1 ? numberOfBeds : ''} ${formattedName} ${
        numberOfBeds > 1
          ? `${bedName === 'PULL_OUT' ? suffix + 'es' : suffix + 's'}`
          : suffix
      }`
      // Append comma if not last bed in list
      if (i < orderedBeds.length - 1) {
        bedNames += ', '
      }
    } else {
      bedNames += `${formattedName} ${suffix}`
    }

    return bedNames
  }

  const getBedImages = () => {
    let imageList: Array<JSX.Element | null> = []
    for (let i = 0; i < orderedBeds.length; i++) {
      // Generate number of icons based on bed.number
      for (let j = 0; j < orderedBeds[i].number; j++) {
        const Icon = bedsData[orderedBeds[i].type].svg
        imageList.push(
          Icon ? (
            <Icon
              className={style.icon}
              key={`floor-${floor}-${orderedBeds[i].type}-${j}-image`}
            />
          ) : null,
        )
      }
    }
    // Shorten imageList and display 5+ icon
    if (totalNumberOfBeds > 4) {
      imageList = imageList.slice(0, 4)
      imageList.push(<FivePlusBeds className={style.icon} key={'5+'} />)
    }
    return imageList
  }

  return (
    <div className={style.bedroom}>
      <div>{getBedImages()}</div>
      <span className={style.name}>{name}</span>
      <div>
        {orderedBeds.map((bed: Bed, i: number) => (
          <span className={style.type} key={`floor-${floor}-${bed.type}-name`}>
            {getBedNames(i, bed.number, bed.type)}
          </span>
        ))}
      </div>
    </div>
  )
}

export default Bedroom
