import { memo } from 'react'
import classNames from 'classnames'

import styles from './ListingAvgPrice.module.scss'

import { avgNightCopy } from 'config/Search'

import { setPrice } from 'utils/Strings'

type ListingAvgPriceProps = {
  avgPrice: number
  className?: string
}

const ListingAvgPrice = ({ avgPrice, className }: ListingAvgPriceProps) => (
  <span className={classNames(styles.avgPrice, className)}>
    {setPrice(avgPrice)}
    {avgNightCopy}
  </span>
)

export default memo(ListingAvgPrice)
