import { useEffect, useState, useMemo, useCallback } from 'react'
import type { LatLon } from 'use-places-autocomplete'

import type { BookingDates } from 'context/BookingContext'

import {
  augmentedSearchIndex,
  buildAugmentedGeoSearchRequestParams,
  buildNumericFilters,
  filterBuilder,
  NumericFilter,
  buildFacetFilters,
} from 'config/AlgoliaClient'

import debounce from 'utils/debounce'

// eslint-disable-next-line import/order
import { searchUtils } from 'store/search'

import type { Listing } from 'types/Listing.type'

export type UseListingsRecommendProps = {
  geoloc: LatLon
  omitIds: Array<string>
  recommendedAttributes: {
    maxOccupancy: number
    nbBedrooms: number
    amenity?: Array<string>
    accessibility?: Array<string>
    view?: Array<string>
    location?: Array<string>
    propertyType?: Array<string>
  }
  avgPrice?: number
  bookingDates?: BookingDates
}

const RADIUS_METERS = 32186 //20 Miles
const BEDS_MIN_THRESHOLD = 4
const RECOMMENDED_LISTINGS_MIN_THRESHOLD = 15

const getBedroomsFilter = (nbBedrooms: number = 0): Array<string> => {
  const isOverthreshold = nbBedrooms >= BEDS_MIN_THRESHOLD
  const bedroomsFilter: NumericFilter = {
    gte: isOverthreshold ? nbBedrooms - 1 : nbBedrooms,
  }

  return buildNumericFilters({ bedrooms: bedroomsFilter })
}

const getMaxOccupancyFilter = (maxOccupancy: number = 0): Array<string> => {
  const maxOccupancyFilter = searchUtils.buildMaxOccupancyFilter(maxOccupancy)

  return buildNumericFilters({ maxOccupancy: maxOccupancyFilter })
}

const getAvgRatingFilter = (rating: number | undefined = 4): NumericFilter => {
  const ratingFilter: NumericFilter = {
    gte: rating,
    eq: 0,
    join: 'OR',
  }

  return ratingFilter
}

const buildAttributesFilter = (
  searchAttributes: UseListingsRecommendProps['recommendedAttributes'],
): Array<string> => {
  const bedroomsFilter = getBedroomsFilter(searchAttributes.nbBedrooms)
  const maxOccupancyFilter = getMaxOccupancyFilter(
    searchAttributes.maxOccupancy,
  )
  return [...bedroomsFilter, ...maxOccupancyFilter]
}

const getAvailabilityFilter = (dates?: BookingDates) => {
  if (!dates) return

  const { start, end } = dates
  return searchUtils.buildAvailabilityFilters(start, end)
}

const getPriceFilter = (dates?: BookingDates, avgPrice: number = 0) => {
  const { start, end } = dates || {}
  let minPrice = avgPrice ? Math.floor(avgPrice - 100) : 0
  const maxPrice = avgPrice ? Math.floor(avgPrice + 100) : 0

  minPrice = minPrice < 0 ? 0 : minPrice
  return searchUtils.buildPriceFilters(start, end, minPrice, maxPrice, false)
}

const getMinimunStayingFilter = (dates?: BookingDates) => {
  if (!dates) {
    return
  }

  const { start, end } = dates
  return searchUtils.buildMinimumStayFilters(start, end)
}

const getObjectIDFilter = (ids: Array<string>): Array<string> => {
  return ids.map((id) => `-${id}`)
}

const useRecommendListings = (props: UseListingsRecommendProps) => {
  const {
    geoloc: { lat, lng },
    omitIds,
    recommendedAttributes,
    bookingDates,
    avgPrice,
  } = props
  const [listings, setListings] = useState<Array<Listing>>([])
  const filters = useMemo((): string => {
    const objectId = getObjectIDFilter(omitIds)
    const availability = getAvailabilityFilter(bookingDates)
    const minStay = getMinimunStayingFilter(bookingDates)
    const [priceKey, priceFilter] = getPriceFilter(bookingDates, avgPrice)
    const avgRating = getAvgRatingFilter()

    return filterBuilder({
      objectId,
      availability,
      minStay,
      avgRating,
      [priceKey]: priceFilter,
    })
  }, [omitIds, bookingDates, avgPrice])

  const numericFilters = useMemo((): Array<string> => {
    return buildAttributesFilter(recommendedAttributes ?? {})
  }, [recommendedAttributes])

  const facetFilters = useMemo((): string[][] | undefined => {
    const { amenity, accessibility, view, location, propertyType } =
      recommendedAttributes
    return buildFacetFilters({
      amenity,
      accessibility,
      propertyType: [
        {
          values: propertyType ?? [],
        },
      ],
      location: [
        {
          values: location ?? [],
        },
      ],
      view: [
        {
          values: view ?? [],
        },
      ],
    })
  }, [recommendedAttributes])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchRecommendedListings = useCallback(
    debounce(
      async (
        lat,
        lng,
        numericFilters,
        filters,
        facetFilters,
        setListings,
        isMounted,
      ) => {
        if (!lat || !lng || !filters || !numericFilters) return
        const augmentedSearchRequest = buildAugmentedGeoSearchRequestParams(
          { lat, lng, partialMatch: false },
          undefined,
          RADIUS_METERS,
        )

        const listings = await augmentedSearchIndex.search<Listing>(
          '',
          {
            ...augmentedSearchRequest,
            hitsPerPage: 20,
            filters,
            facetFilters,
            numericFilters,
            analytics: false,
            clickAnalytics: false,
          },
          true,
        )
        if (isMounted) {
          if (listings.nbHits >= RECOMMENDED_LISTINGS_MIN_THRESHOLD) {
            setListings(listings.hits.slice(0, 10)) //take first 10
          } else {
            setListings([])
          }
        }
      },
      1500,
    ),
    [],
  )

  useEffect(() => {
    fetchRecommendedListings(
      lat,
      lng,
      numericFilters,
      filters,
      facetFilters,
      setListings,
      true,
    )

    return () => {
      fetchRecommendedListings(
        lat,
        lng,
        numericFilters,
        filters,
        facetFilters,
        setListings,
        false,
      )
    }
  }, [lat, lng, omitIds, numericFilters, filters, facetFilters, setListings])

  return {
    listings,
  }
}

export default useRecommendListings
