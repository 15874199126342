import React from 'react'
import Head from 'next/head'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import type { CombinedListing } from 'utils/staticData'
import { metaTitleFormula } from 'utils/Listings'

export type ListingOGDataProps = {
  title: string
  metaDescription: string
  imageUrl: string | undefined
  listing?: CombinedListing | null
}

const ListingOGData: React.FC<ListingOGDataProps> = ({
  title,
  metaDescription,
  imageUrl,
  listing,
}) => {
  const router = useAugmentedRouter()

  const metaTitle = metaTitleFormula(listing)

  return (
    <Head>
      <meta content="website" property="og:type" />
      {/**
      repetative because React.Fragment not supported in Helmet, no nesting allowed
       */}
      {title && (
        <meta content={`${metaTitle} | Evolve`} property={'og:title'} />
      )}
      {title && (
        <meta content={`${metaTitle} | Evolve`} name={'twitter:title'} />
      )}
      {metaDescription && (
        <meta content={metaDescription} property="og:description" />
      )}
      {metaDescription && (
        <meta content={metaDescription} name="twitter:description" />
      )}
      <meta
        content={process.env.NEXT_PUBLIC_ORIGIN + router.asPath.split('?')[0]}
        property="og:url"
      />
      {imageUrl && <meta content={imageUrl} property="og:image" />}
      {imageUrl && <meta content={imageUrl} name="twitter:image" />}
      <meta content="Evolve Vacation Rental" property="og:site_name" />
      <meta content="en_US" property="og:locale" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content="@EvolveVR" name="twitter:site" />
      <meta content="evolve.com" name="twitter:domain" />
    </Head>
  )
}

export default ListingOGData
