import { ComponentType, useMemo, memo, useCallback, useEffect } from 'react'

import { useSelect } from 'store/index'
import { searchSelectors } from 'store/search'

import { isBelowViewportWidth } from 'selectors/uiState'

import type { BookingDates } from 'context/BookingContext'

import useAnalytics from 'hooks/useAnalytics'

import BaseCarousel from 'components/Carousels/BaseCarousel'

import ListingCard from './ListingCard/ListingCard'
import styles from './RecommendedListings.module.scss'
import useRecommendListings from './useRecommendListings'
import useSearchUrl from './useSearchUrl'
import SeeMoreCard from './SeeMoreCard'
import type { UseListingsRecommendProps } from './useRecommendListings'

import { SmallTabletBreakpoint } from 'config/Breakpoints'

import type { CombinedListing } from 'utils/staticData'

type RecommendedListingsProps = {
  isMobile?: boolean
  isFilterApply?: boolean
  listing: CombinedListing
  bookingDates: BookingDates
  avgPrice?: number
  selectedAmenities: {
    accessibility: string[]
    amenity: string[]
    location: string[]
    view: string[]
  }
  selectedPropertyTypes: string[]
}

const withState = <
  ChildProps extends Omit<
    RecommendedListingsProps,
    'selectedAmenities' | 'selectedPropertyTypes' | 'isMobile' | 'isFilterApply'
  >,
>(
  Component: ComponentType<RecommendedListingsProps>,
) => {
  const RecommendedListings = (props: ChildProps) => {
    const isMobile = useSelect((state) =>
      isBelowViewportWidth(state, SmallTabletBreakpoint),
    )
    const selectedAmenities = useSelect(searchSelectors.allSelectedAmenities)
    const selectedPropertyTypes = useSelect(
      searchSelectors.selectedPropertyTypes,
    )
    const isFilterApply = useSelect(searchSelectors.isFilterApply)

    const stateProps = {
      selectedAmenities,
      selectedPropertyTypes,
      isMobile,
      isFilterApply,
    }
    return <Component {...stateProps} {...props} />
  }

  return RecommendedListings
}

const RecommendedListingsComponent = ({
  isFilterApply,
  isMobile,
  listing,
  bookingDates,
  avgPrice,
  selectedAmenities,
  selectedPropertyTypes,
}: RecommendedListingsProps) => {
  const { objectId: listingId } = listing
  const searchUrl = useSearchUrl(listing)
  const recommededListingsProps = useMemo((): UseListingsRecommendProps => {
    const { geoloc, maxOccupancy, objectId, bedrooms: nbBedrooms } = listing
    const { amenity, accessibility, view, location } = selectedAmenities
    const propertyType = selectedPropertyTypes
    const omitIds = [objectId]

    return {
      geoloc,
      omitIds,
      recommendedAttributes: {
        maxOccupancy,
        nbBedrooms,
        amenity,
        accessibility,
        view,
        location,
        propertyType,
      },
      avgPrice,
      bookingDates,
    }
  }, [
    listing,
    avgPrice,
    bookingDates,
    selectedAmenities,
    selectedPropertyTypes,
  ])
  const { listings } = useRecommendListings(recommededListingsProps)
  const listingsLength = listings.length
  const { viewRecommendedListings, clickRecommendedListing } = useAnalytics()

  const onClickListing = useCallback(
    (listing, meta) => {
      clickRecommendedListing({ ...listing, ...meta })
    },
    [clickRecommendedListing],
  )

  useEffect(() => {
    if (listingId && listingsLength) {
      viewRecommendedListings({ listingId, bookingDates })
    }
  }, [listingsLength, listingId, bookingDates, viewRecommendedListings])

  return listings.length ? (
    <div className={styles.recommended__listings}>
      <h4>Other properties that match your search</h4>
      <p>These properties match your search criteria, and are nearby:</p>
      <div className={styles.listings__carrousel}>
        <BaseCarousel
          dragFree={isMobile}
          pagination
          slideClassName={styles.carrousel__slide}
          slidesToScroll={isMobile ? 1 : 2}
        >
          {listings.map((listing, index) => {
            const isLastCard = index === listings.length - 1
            const Card = () => (
              <ListingCard
                bookingDates={bookingDates}
                listing={listing}
                onClick={onClickListing}
                position={index + 1}
                selectedAmenities={selectedAmenities}
                showAmenities={isFilterApply}
              />
            )

            if (!isLastCard) {
              return (
                <div className={styles.slide} key={listing.objectId}>
                  <Card />
                </div>
              )
            }

            if (isMobile) {
              return (
                <div className={styles.slide} key={listing.objectId}>
                  <Card />
                  <SeeMoreCard isMobile url={searchUrl} />
                </div>
              )
            } else {
              return (
                <div className={styles.slide} key={listing.objectId}>
                  <SeeMoreCard url={searchUrl} />
                </div>
              )
            }
          })}
        </BaseCarousel>
      </div>
    </div>
  ) : null
}

export const RecommendedListings = memo(RecommendedListingsComponent)

export default withState(RecommendedListings)
