import React, { useState } from 'react'

import style from './NearbyActivities.module.scss'

// Components
import SeeMoreButton from '../SeeMoreButton'

// Types
import type { CombinedListing } from 'utils/staticData'

type NearbyActivitiesProps = {
  listing: CombinedListing
  detailMenuRefs: React.MutableRefObject<Record<string, HTMLElement | null>>
}

const NearbyActivities: React.FC<NearbyActivitiesProps> = ({
  listing,
  detailMenuRefs,
}) => {
  const [open, setOpen] = useState(false)

  let activities: string[] = []

  if (listing.amenities && listing.amenities['Area Activities']) {
    activities = listing['amenities']['Area Activities']
  } else if ('units' in listing && listing.units.length) {
    activities = listing['units'][0]['amenities']
      .filter(({ category }) => category === 'Area Activities')
      .map((amenity: { name: string; category: string }) => amenity.name)
  }

  const renderActivities = (activities: string[]) => {
    const renderedAct: JSX.Element[] = []
    if (activities) {
      const listLength = open ? activities.length : 8
      for (let i = 0; i < listLength; i++) {
        renderedAct.push(
          <span className={style.activity} key={i}>
            {activities[i]}
          </span>,
        )
      }
    }
    return renderedAct
  }

  return activities.length ? (
    <div
      className={style.amenities}
      id="activities"
      ref={(el) => (detailMenuRefs.current['activities'] = el)}
    >
      <h2 className={`section-subtitle`}>Nearby Activities</h2>
      <div className={style.activityGrid}>{renderActivities(activities)}</div>
      <SeeMoreButton
        id="nearby_activities_see_more_btn"
        open={open}
        setOpen={setOpen}
      />
    </div>
  ) : null
}

export default NearbyActivities
