import React from 'react'

import style from './Pagination.module.scss'

import { maxPaginationLinks } from 'config/Pagination'

import PreviousIcon from 'assets/icons/icon-paginationPrevious.svg'
import NextIcon from 'assets/icons/icon-paginationNext.svg'
import ElipsisIcon from 'assets/icons/icon-paginationElipsis.svg'

const ReviewPagination = ({ page, onPageChange, totalPages }) => {
  const pages = Array.from(Array(totalPages))
  let firstSeparatorAdded = false
  let secondSeparatorAdded = false

  for (let i = 0; i < pages.length; i++) {
    pages[i] = i + 1
  }

  const isActivePage = (p) => {
    return +p === +page
  }

  const isPageInRange = (p) => {
    if (pages.length <= maxPaginationLinks) {
      // render all pages when they are shorter than the max length
      return true
    } else if (p === page) {
      // always render the current page
      return true
    } else if (p === 1) {
      // always render the first page
      return true
    } else if (p === pages.length) {
      // always render the last page
      return true
    } else if (p >= page - 2 && p <= page + 2) {
      // render 2 pages before, and 2 pages after current page
      return true
    }

    return false
  }

  const shouldRenderFirstSeparator = (p) => {
    if (firstSeparatorAdded) {
      return false
    }

    if (p < page) {
      return true
    }

    return false
  }

  const shouldRenderSecondSeparator = (p) => {
    if (secondSeparatorAdded) {
      return false
    }

    if (p === pages.length - 1) {
      return true
    }

    return false
  }

  return (
    <div className={style.pagination}>
      <ul className={style.pages}>
        {page && page > 1 && (
          <li onClick={() => onPageChange(page - 1)}>
            <PreviousIcon className={style.arrow} />
          </li>
        )}
        {pages.map((p, i) => {
          if (isPageInRange(p)) {
            return (
              <li
                className={isActivePage(p) ? style.active : ''}
                key={i}
                onClick={() => onPageChange(p)}
              >
                {p}
              </li>
            )
          } else {
            if (shouldRenderFirstSeparator(p)) {
              firstSeparatorAdded = true
              return (
                <li className={style.elipsis} key={i}>
                  <ElipsisIcon />
                </li>
              )
            } else if (shouldRenderSecondSeparator(p)) {
              secondSeparatorAdded = true
              return (
                <li className={style.elipsis} key={i}>
                  <ElipsisIcon />
                </li>
              )
            }
          }

          return false
        })}
        {page < totalPages && (
          <li onClick={() => onPageChange(page + 1)}>
            <NextIcon className={style.arrow} />
          </li>
        )}
      </ul>
    </div>
  )
}

export default ReviewPagination
