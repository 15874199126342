import type { UrlObject } from 'url'

import { memo, useMemo } from 'react'
import classNames from 'classnames'

import type { BookingDates } from 'context/BookingContext'

import useAvgPrice from 'hooks/useAvgPrice'

import CustomLink from 'components/Link/CustomLink'
import ListingAvgPrice from 'components/Search/ListingAvgPrice'
import ListingBasicInfo from 'components/Search/ListingBasicInfo'
import ListingLocation from 'components/Search/ListingLocation'
import ListingRating from 'components/Search/ListingRating'

import styles from './ListingCard.module.scss'
import ListingImage from './ListingImage'
import ListingAmenities from './ListingAmenities'

import { buildListingLink } from 'utils/Listings'

import type { Listing } from 'types/Listing.type'

type ListingCardProps = {
  listing: Listing
  bookingDates: BookingDates
  selectedAmenities: Record<string, string[]>
  position?: number
  showAmenities?: boolean
  onClick: (listing: Listing, meta?: any) => void
}

const ListingCard = ({
  listing,
  bookingDates,
  selectedAmenities,
  position,
  showAmenities,
  onClick,
}: ListingCardProps) => {
  const {
    pricePerDay,
    averagePerNight: avgPricePerNight,
    propertyType,
    fees = {},
  } = listing

  const { avgPrice } = useAvgPrice({
    pricePerDay,
    avgPricePerNight,
    bookingDates,
    fees,
  })
  const listingUrl: UrlObject = useMemo(
    () => buildListingLink(listing),
    [listing],
  )
  return (
    <div className={styles.card}>
      <CustomLink
        href={listingUrl}
        onClick={() => onClick(listing, { position, price: avgPrice })}
      >
        <ListingImage {...listing.images![0]} propertyType={propertyType} />
        <div
          className={classNames(styles.info, {
            [styles.amenities__hidden]: !showAmenities,
          })}
        >
          <h5>{listing.headline}</h5>
          <div className={styles.middle__section}>
            <ListingLocation
              city={listing.city}
              className={styles.location}
              country={listing.country}
              state={listing.state}
            />
            <ListingBasicInfo
              bathrooms={listing.bathrooms}
              bedrooms={listing.bedrooms}
              className={styles.basic__info}
              maxOccupancy={listing.maxOccupancy}
            />
            {showAmenities && (
              <ListingAmenities
                inListing={listing.amenities}
                selected={selectedAmenities}
              />
            )}
          </div>
          <div className={styles.bottom__section}>
            <ListingRating
              className={styles.rating}
              rating={listing.averageRating}
              reviews={listing.numberOfReviews}
            />
            <ListingAvgPrice
              avgPrice={avgPrice ?? 0}
              className={styles.price}
            />
          </div>
        </div>
      </CustomLink>
    </div>
  )
}

export default memo(ListingCard)
