import { useEffect, memo } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import styles from './BaseCarousel.module.scss'

type PageIndicatorProps = {
  pages: number[]
  activePage: number
}

const PageIndicator = ({ pages, activePage }: PageIndicatorProps) => {
  const [emblaRef, emblaAPI] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    draggable: false,
    speed: 20,
  })

  useEffect(() => {
    if (!emblaAPI) return
    emblaAPI.scrollTo(activePage)
  }, [emblaAPI, activePage])

  return (
    <div className={`${styles.embla} ${styles.embla__dots}`}>
      <div className={styles.embla__viewport} ref={emblaRef}>
        <div className={`${styles.embla__container}`}>
          {pages.map((_, index) => (
            <div key={index}>
              <div
                className={`${styles.embla__dot} ${
                  index === activePage ? styles.is_selected : ''
                }`}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default memo(PageIndicator)
