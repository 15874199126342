import { memo } from 'react'
import classNames from 'classnames'

import styles from './ListingBasicInfo.module.scss'

type ListingBasicInfoProp = {
  maxOccupancy: number
  bedrooms: number
  bathrooms: number
  className?: string
}

const ListingBasicInfo = ({
  maxOccupancy,
  bedrooms,
  bathrooms,
  className,
}: ListingBasicInfoProp) => (
  <div className={classNames(styles.container, className)}>
    <span>Sleeps {maxOccupancy}</span>
    <span>{bedrooms} BR</span>
    <span>{bathrooms} BA</span>
  </div>
)

export default memo(ListingBasicInfo)
