import React, { useMemo } from 'react'

import DetailMap from './Detail-Map'
import style from './Result-Map.module.scss'

import type { CombinedListing } from 'utils/staticData'

export type ResultMapProps = {
  listing: CombinedListing
  detailMenuRefs: React.MutableRefObject<Record<string, HTMLElement | null>>
}

const ResultMap: React.FC<ResultMapProps> = ({ listing, detailMenuRefs }) => {
  const location = useMemo(() => listing._geoloc, [listing])

  return location ? (
    <div
      className={style.map}
      id="map"
      ref={(el) => (detailMenuRefs.current['map'] = el)}
    >
      <h2 className={`section-subtitle`}>Map</h2>
      <div className={style.mapWrapper}>
        <DetailMap location={location} />
      </div>
    </div>
  ) : null
}

export default ResultMap
