import CustomLink from 'components/Link/CustomLink'

import styles from './SeeMoreCard.module.scss'

import HouseIcon from 'assets/icons/icon-house.svg'

type SeeMoreCardProps = {
  isMobile?: boolean
  url: string
}

const SeeMoreCard = ({ url, isMobile }: SeeMoreCardProps) => (
  <CustomLink className={styles.card} href={url}>
    <HouseIcon />
    <p>
      {isMobile ? 'Tap to explore' : 'Click to see'} more similar properties
    </p>
  </CustomLink>
)

export default SeeMoreCard
