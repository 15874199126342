import React from 'react'

import style from './Location-Details.module.scss'

import { parseDataString } from 'utils/Strings'
import type { CombinedListing } from 'utils/staticData'

export type LocationDetailsProps = {
  listing: CombinedListing
  detailMenuRefs: React.MutableRefObject<Record<string, HTMLElement | null>>
}

const LocationDetails: React.FC<LocationDetailsProps> = ({
  listing,
  detailMenuRefs,
}) => {
  let details = ''

  if ('adContent' in listing) {
    const dets = listing['adContent'].filter(
      ({ type }) => type === 'neighborhood',
    )
    details = dets[0]['text']
  }

  return details && details !== 'null' ? (
    <div
      className={style.location}
      id="location"
      ref={(el) => (detailMenuRefs.current['location'] = el)}
    >
      <h2 className={`section-subtitle`}>Location Details</h2>
      <div className={style.content}>
        {parseDataString(details)?.map((item, i) => {
          return <p key={i}>{item}</p>
        })}
      </div>
    </div>
  ) : null
}

export default LocationDetails
