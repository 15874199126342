import { useMemo } from 'react'
import uniq from 'lodash/uniq'

import styles from './ListingCard.module.scss'

import CrossIcon from 'assets/icons/icon-cross.svg'
import CheckMarkIcon from 'assets/icons/icon-check.svg'

type ListingAmenitiesProps = {
  selected: Record<string, string[]>
  inListing: Record<string, string[]>
}

type Amenity = {
  label: string
  included?: boolean
}

const fallbackAmenities = [
  'Pets Allowed',
  'Kitchen',
  'Wifi/Internet',
  'Private Hot Tub',
]

const ListingAmenities = ({ selected, inListing }: ListingAmenitiesProps) => {
  const amenities = useMemo((): Amenity[] => {
    const categories = Object.keys(selected)
    let selectedAmenities = categories.reduce((acc, category) => {
      return [...acc, ...selected[category]]
    }, [] as string[])

    if (!selectedAmenities.length) return []

    selectedAmenities.push(...fallbackAmenities)
    selectedAmenities = uniq(selectedAmenities)

    const inListingAmenities = Object.keys(inListing).reduce(
      (acc, caterogy) => {
        return [...acc, ...inListing[caterogy]]
      },
      [] as string[],
    )

    const amenities = selectedAmenities.map((amenity): Amenity => {
      const included = inListingAmenities.includes(amenity)

      return { included, label: amenity }
    })

    return amenities.slice(0, 4)
  }, [selected, inListing])

  return amenities.length ? (
    <div className={styles.amenities}>
      {amenities.map(({ label, included }) => (
        <div key={label}>
          {included ? (
            <CheckMarkIcon />
          ) : (
            <div className="cross">
              <CrossIcon />
            </div>
          )}
          <span>{label}</span>
        </div>
      ))}
    </div>
  ) : null
}

export default ListingAmenities
