import React, { useState } from 'react'

import useAnalytics from 'hooks/useAnalytics'

import style from './Amenities.module.scss'
import SeeMoreButton from './SeeMoreButton'
import { ListingAmenities } from './ListingPage/ListingPage.types'

import type { CombinedListing } from 'utils/staticData'

const filterAmenities = (amenities: any[]) => {
  // reduce categories into objects with key
  amenities = amenities.reduce((grouped, amenity) => {
    grouped[amenity.category] = grouped[amenity.category] || []
    grouped[amenity.category].push(amenity)
    return grouped
  }, Object.create(null))

  // add expanded property to categories
  amenities = Object.keys(amenities).map((key) => {
    return {
      category: key,
      items: amenities[key],
      expanded: false,
    }
  })

  // remove duplicate amenities
  for (let i = 0; i < amenities.length; i++) {
    const cat = amenities[i]

    cat.items = cat.items.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj['name']).indexOf(obj['name']) === pos
    })
  }

  return amenities
}

type AmenitiesProps = {
  listing: CombinedListing
  detailMenuRefs: React.MutableRefObject<Record<string, HTMLElement | null>>
  featured: Array<ListingAmenities>
}

const Amenities: React.FC<AmenitiesProps> = ({
  listing,
  detailMenuRefs,
  featured,
}) => {
  const [amenities, setAmenities] = useState(
    listing.units?.length
      ? filterAmenities(
          listing.units[0]['amenities'].filter(
            ({ category }) =>
              category !== 'Key Amenities' && category !== 'Area Activities',
          ),
        )
      : [],
  )
  const [open, setOpen] = useState(false)
  const { clickProductSection } = useAnalytics()

  const renderSubAmenitiesGrid = (category, items, expanded) => {
    if (items && (items.length <= 8 || (items.length > 8 && expanded))) {
      return (
        <>
          <ul className={style.amenitiesGrid}>
            {items.map((amenity, a) => {
              return (
                <li className={style.amenity} key={a}>
                  {amenity.name}
                </li>
              )
            })}
          </ul>
          {expanded && (
            <SeeMoreButton
              cssClass={style.amenitiesSeeMoreBtn}
              id={`amenities_${category
                .split(' ')
                .join('_')
                .toLowerCase()}_see_more_btn`}
              open={expanded}
              setOpen={() => {
                const newAmenities = amenities.map((amenity) => {
                  if (amenity.category === category) {
                    return {
                      ...amenity,
                      expanded: false,
                    }
                  }

                  return amenity
                })

                setAmenities(newAmenities)
              }}
            />
          )}
        </>
      )
    } else {
      if (items && items.length > 8 && !expanded) {
        return (
          <>
            <ul className={style.amenitiesGrid}>
              {items.map((amenity, a) => {
                if (a < 8) {
                  return (
                    <li className={style.amenity} key={a}>
                      {amenity.name}
                    </li>
                  )
                }

                return null
              })}
            </ul>
            <SeeMoreButton
              cssClass={style.amenitiesSeeMoreBtn}
              id={`amenities_${category
                .split(' ')
                .join('_')
                .toLowerCase()}_see_more_btn`}
              open={expanded}
              setOpen={() => {
                const newAmenities = amenities.map((amenity) => {
                  if (amenity.category === category) {
                    return {
                      ...amenity,
                      expanded: true,
                    }
                  }

                  return amenity
                })

                setAmenities(newAmenities)
              }}
            />
          </>
        )
      }
    }
  }

  const renderSubAmenitiesGroup = (group, key) => {
    const { category, items, expanded } = group

    return (
      <li className={style.amenityGroup} key={key}>
        <h3>{category}</h3>
        {renderSubAmenitiesGrid(category, items, expanded)}
      </li>
    )
  }

  return (featured && featured.length) || (amenities && amenities.length) ? (
    <div
      className={style.amenities}
      id="amenities"
      ref={(el) => (detailMenuRefs.current['amenities'] = el)}
    >
      <h2 className={`section-subtitle`}>Amenities</h2>
      {featured && featured.length ? (
        <ul className={style.amenitiesGrid}>
          {featured.map((amenity, i) => {
            return (
              <li className={style.amenity} key={i}>
                {amenity.name}
              </li>
            )
          })}
        </ul>
      ) : null}
      {open && (
        <ul>
          {amenities.map((group, c) => {
            return renderSubAmenitiesGroup(group, c)
          })}
        </ul>
      )}
      {!open && amenities && amenities.length ? (
        <SeeMoreButton
          id="amenities_see_more_btn"
          open={open}
          setOpen={() => {
            setOpen(!open)
            clickProductSection({
              listingId: listing.objectID,
              section: 'Amenities',
              action: !open ? 'Open' : 'Close',
            })
          }}
        />
      ) : null}
    </div>
  ) : null
}

export default Amenities
