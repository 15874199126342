import React, { useState } from 'react'

import useAnalytics from 'hooks/useAnalytics'

import CustomLink from 'components/Link/CustomLink'

import Accessibility from './Accessibility'
import SeeMoreButton from './SeeMoreButton'
import style from './Description.module.scss'

import { parseDataString } from 'utils/Strings'
import formatTime from 'utils/dates/formatTime'
import type { CombinedListing } from 'utils/staticData'

import ConciergeBellIcon from 'assets/icons/concierge-bell-icon.svg'

type DescriptionProps = {
  listing: CombinedListing
}

const Instruction = ({ label, detail }: { label: string; detail: string }) => (
  <div className={`${style.instructionItem}`}>
    <span className={`${style.sectionHeading}`}>{label}</span>
    <span className={style.instructionCopy}>{detail}</span>
  </div>
)

const Description: React.FC<DescriptionProps> = ({ listing }) => {
  const [open, setOpen] = useState(false)
  const formattedCheckInTime = listing
    ? formatTime(listing['Check-in Time'])
    : null
  const formattedCheckOutTime = listing
    ? formatTime(listing['Check-out Time'])
    : null

  const { clickProductSection } = useAnalytics()

  let description: string[] | undefined
  let property: string[] | undefined
  let rules: string[] | undefined
  let notes: string[] | undefined
  let rentalAgreement = ''

  if (listing && 'adContent' in listing) {
    if (!description) {
      const desc = listing['adContent'].filter(({ type }) => type === 'summary')
      description = parseDataString(desc[0]['text'])
    }

    if (!property) {
      const space = listing['adContent'].filter(({ type }) => type === 'space')
      property = parseDataString(space[0]['text'])
    }

    if (!rules) {
      const houseRules = listing['adContent'].filter(
        ({ type }) => type === 'houseRules',
      )
      rules = parseDataString(houseRules[0]['text'])
    }

    if (!notes) {
      const notesList = listing['adContent'].filter(
        ({ type }) => type === 'notes',
      )
      const parsedNotes = parseDataString(notesList[0]['text'])
      notes = parsedNotes?.[0] !== 'null' ? parsedNotes : undefined
    }
  }

  if (!rentalAgreement && listing && 'Rental Agreement Link' in listing) {
    rentalAgreement = listing['Rental Agreement Link']
  }

  const renderContent = (str) => {
    const content: JSX.Element[] = []

    if (str && str.length) {
      for (let i = 0; i < str.length; i++) {
        if (str[i] !== 'null') {
          content.push(
            <div
              data-type={`${str[i].trim().length ? 'content' : 'line-break'}`}
              key={i}
            >
              <p className={style.descriptionContent}>{str[i]}</p>
            </div>,
          )
        }
      }
    }

    return content
  }

  const shouldRender = () => {
    if (
      listing &&
      (description ||
        property ||
        rules ||
        rentalAgreement ||
        ('units' in listing && listing.units.length) ||
        'amenities' in listing ||
        'Max Occupancy' in listing)
    ) {
      return true
    }

    return false
  }

  return shouldRender() ? (
    <div className={style.description}>
      <h2 className={`section-subtitle`}>Description</h2>
      {!open ? (
        description && renderContent(description)
      ) : (
        <>
          {description && (
            <div className={style.section}>{renderContent(description)}</div>
          )}
          {property && (
            <div className={style.section}>
              <h2 className={`section-subtitle`}>The Property</h2>
              {renderContent(property)}
            </div>
          )}
          {notes && (
            <div className={style.section}>
              <h2 className={`section-subtitle`}>Additional Details</h2>
              {renderContent(notes)}
            </div>
          )}
          {rules && (
            <div className={style.section}>
              <h2 className={`section-subtitle`}>
                House Rules and Additional Information
              </h2>
              <div className={style.instructionCheckTime}>
                <ConciergeBellIcon width={25} />
                {formattedCheckInTime && (
                  <Instruction
                    detail={formattedCheckInTime}
                    label="Check-in:"
                  />
                )}
                <div>•</div>
                {formattedCheckOutTime && (
                  <Instruction
                    detail={formattedCheckOutTime}
                    label="Check-out:"
                  />
                )}
              </div>
              {renderContent(rules)}
            </div>
          )}
          {rentalAgreement && (
            <CustomLink
              className={style.rentalAgreement}
              href={`/rental-agreement/${listing.objectID}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              View Rental Agreement
            </CustomLink>
          )}
          <Accessibility listing={listing} />
        </>
      )}
      <SeeMoreButton
        id="description_see_more_btn"
        open={open}
        setOpen={() => {
          setOpen(!open)
          clickProductSection({
            section: 'Description',
            listingId: listing.objectID,
            action: !open ? 'Open' : 'Close',
          })
        }}
      />
    </div>
  ) : null
}

export default Description
