export const buildSocialShareLinks = (url: string) => {
  const encodedUrl = encodeURIComponent(url)

  const facebook = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&utm_medium=facebook`
  const twitter = `https://twitter.com/home?status=${encodedUrl}&utm_medium=twitter`
  const pinterest = `http://pinterest.com/pin/create/link/?url=${encodedUrl}&utm_medium=pinterest`
  const linkedin = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&utm_medium=linkedin`
  const email = `mailto:?subject=I wanted you to see this&body=Check this out: ${encodeURIComponent(
    `${url}&utm_medium=email`,
  )}`

  return {
    facebook,
    twitter,
    pinterest,
    linkedin,
    email,
  }
}
