import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import ReCAPTCHA from 'react-google-recaptcha'
import IntlTelInput from 'react-intl-tel-input'
import { useForm, Controller } from 'react-hook-form'

import CustomLink from 'components/Link/CustomLink'

import style from './Question-Form.module.scss'

import Checkbox from '../Forms/Checkbox'

import 'react-intl-tel-input/dist/main.css'
import type { QuoteResponse } from 'types/externalData'

const messageMinLength = 4
const messageMaxLength = 2500

type QuestionFormInputs = {
  firstName: string
  lastName: string
  email: string
  phone: string
  message: string
}

type QuestionFormProps = {
  closeModal: () => void
  country: string
  onSubmit: (data: any) => Promise<void>
  recaptchaChange: ReCAPTCHA['props']['onChange']
  recaptchaError: boolean
  recaptchaRef: React.MutableRefObject<null>
  submitted: boolean
  quote: QuoteResponse | null
}

const QuestionForm = ({
  recaptchaChange,
  recaptchaError,
  submitted,
  closeModal,
  onSubmit,
  country,
  quote,
}: QuestionFormProps) => {
  const { register, handleSubmit, control, setValue, errors } =
    useForm<QuestionFormInputs>()
  const [fullPhoneNumber, setFullPhoneNumber] = useState('')
  const [subscribeChecked, setSubscribeChecked] = useState(false)
  const [telMaxLength, setTelMaxLength] = useState<number>()
  const [quoteMissingError, setQuoteMissingError] = useState(false)

  const firstNameRef = useRef<HTMLInputElement | null>(null)
  const lastNameRef = useRef<HTMLInputElement | null>(null)
  const emailRef = useRef<HTMLInputElement | null>(null)
  const phoneRef = useRef<HTMLDivElement | null>(null)
  const messageRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (errors.firstName && firstNameRef.current) {
      firstNameRef.current.focus()
      if (isMobile) {
        window.scrollTo({
          behavior: 'smooth',
          top: firstNameRef.current.offsetTop,
        })
      }
    } else if (errors.lastName && lastNameRef.current) {
      lastNameRef.current.focus()
      if (isMobile) {
        window.scrollTo({
          behavior: 'smooth',
          top: lastNameRef.current.offsetTop,
        })
      }
    } else if (errors.email && emailRef.current) {
      emailRef.current.focus()
      if (isMobile) {
        window.scrollTo({
          behavior: 'smooth',
          top: emailRef.current.offsetTop,
        })
      }
    } else if (errors.phone?.ref && phoneRef.current) {
      errors.phone.ref.focus?.()
      if (isMobile) {
        window.scrollTo({
          behavior: 'smooth',
          top: phoneRef.current.offsetTop,
        })
      }
    } else if (errors.message && messageRef.current) {
      messageRef.current.focus()
      if (isMobile) {
        window.scrollTo({
          behavior: 'smooth',
          top: messageRef.current.offsetTop,
        })
      }
    }
  }, [errors])

  useEffect(() => {
    // Hack to make country code clickable.
    // See https://github.com/patw0929/react-intl-tel-input/issues/365
    if (phoneRef.current) {
      const el = phoneRef.current.querySelector('.selected-dial-code')
      if (el) {
        el.classList.remove('selected-dial-code')
      }
    }
  }, [phoneRef])

  return (
    <form
      className={style.questionForm}
      onSubmit={handleSubmit((data) => {
        if (
          [quote?.checkInDate, quote?.checkInDate, quote?.numberOfAdults].some(
            (reservationParameter) =>
              typeof reservationParameter === 'undefined',
          )
        ) {
          setQuoteMissingError(true)

          return
        }

        setQuoteMissingError(false)
        onSubmit({
          ...data,
          phone: fullPhoneNumber,
          subscribe: subscribeChecked,
        })
      })}
    >
      <div className={`formFlex`}>
        <div className="formInputGroupHalf">
          <label htmlFor="firstName">First Name</label>
          <input
            className={errors.firstName && 'error'}
            data-testid="firstName"
            name="firstName"
            ref={(e) => {
              register(e, { required: true })
              firstNameRef.current = e
            }}
            type="text"
          />
          {errors.firstName && <span>*First name is required</span>}
        </div>
        <div className="formInputGroupHalf">
          <label htmlFor="lastName">Last Name</label>
          <input
            className={errors.lastName && 'error'}
            data-testid="lastName"
            name="lastName"
            ref={(e) => {
              register(e, { required: true })
              lastNameRef.current = e
            }}
            type="text"
          />
          {errors.lastName && <span>*Last name is required</span>}
        </div>
      </div>
      <div className={`formFlex`}>
        <div className="formInputGroupHalf">
          <label htmlFor="email">Email Address</label>
          <input
            className={errors.email && 'error'}
            data-testid="email"
            name="email"
            ref={(e) => {
              register(e, { required: true })
              emailRef.current = e
            }}
            type="email"
          />
          {errors.email && <span>*Email address is required</span>}
        </div>
        <div className="formInputGroupHalf">
          <label htmlFor="phone">Phone Number</label>
          <div ref={phoneRef}>
            <Controller
              control={control}
              defaultValue=""
              name="phone"
              render={({ onChange, value }) => (
                <IntlTelInput
                  autoComplete="tel"
                  containerClassName="intl-tel-input"
                  defaultCountry="us"
                  fieldName="tel"
                  format={true}
                  inputClassName={errors.phone && 'error'}
                  onPhoneNumberChange={(
                    isValid: boolean,
                    value: string,
                    _selectedCountryData: any,
                    fullNumber: string,
                  ) => {
                    isValid && setTelMaxLength(value.length)
                    onChange(value)
                    setFullPhoneNumber(fullNumber)
                  }}
                  onSelectFlag={() => setValue('phone', '')}
                  separateDialCode={true}
                  style={{ width: '100%' }}
                  telInputProps={{ maxLength: telMaxLength }}
                  value={value}
                />
              )}
              rules={{
                required: true,
                maxLength: telMaxLength,
              }}
            />
            {errors.phone && <span>*Phone number is required</span>}
          </div>
        </div>
      </div>
      <div className="formInputGroup">
        <label htmlFor="message">Message</label>
        <textarea
          className={errors.message && 'error'}
          data-testid="message"
          name="message"
          ref={(e) => {
            register(e, {
              required: true,
              minLength: messageMinLength,
              maxLength: messageMaxLength,
            })
            messageRef.current = e
          }}
        />
        {errors.message?.type === 'required' && (
          <span>*Message is required</span>
        )}
        {errors.message?.type === 'minLength' && (
          <span>*Message requires at least {messageMinLength} characters</span>
        )}
        {errors.message?.type === 'maxLength' && (
          <span>*Message cannot exceed {messageMaxLength} characters</span>
        )}
        {quoteMissingError ? (
          <span>
            *Message must include check-in, check-out dates and number of adults
          </span>
        ) : null}
      </div>
      <a
        className={style.link}
        href="https://help.evolvevacationrental.com/s/article/How-to-redeem-a-travel-credit?utm_source=website"
        //onClick={handleQuestionClick}
        id="have_a_travel_credit_btn"
        rel="noopener noreferrer"
        target="_blank"
      >
        Have a Travel Credit?
      </a>
      <div className={style.agreementGroup}>
        {country === 'US' ? (
          <div>
            <p className={style.agreement}>
              By submitting this form, you agree to receive updates and
              marketing communications from Evolve. For more information, please
              read our{' '}
              <CustomLink
                href="/privacy"
                target="_blank"
                title="Privacy Policy"
              >
                privacy policy
              </CustomLink>
              . If you have previously opted out, this action will not affect
              your email subscription status.
            </p>
          </div>
        ) : (
          <div>
            <Checkbox
              checked={subscribeChecked}
              id="subscribe"
              name="subscribe"
              onChange={() => {
                setSubscribeChecked(!subscribeChecked)
              }}
              value={subscribeChecked as any}
            />

            <p className={style.agreement}>
              I agree to receive updates and marketing communications from
              Evolve. For more information, please read our{' '}
              <CustomLink
                href="/privacy"
                target="_blank"
                title="Privacy Policy"
              >
                privacy policy
              </CustomLink>
              .
            </p>
          </div>
        )}
      </div>

      <ReCAPTCHA
        onChange={recaptchaChange}
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
      />

      <div className={style.formBtns}>
        <button
          className={`btn-primary ${style.submit}`}
          disabled={recaptchaError || submitted}
          id="ask_a_question_submit_btn"
          type="submit"
        >
          {submitted ? 'Submitting Form' : 'Submit'}
        </button>
        <button
          className={`btn btn-secondary ${style.cancelBtn}`}
          id="ask_a_question_cancel_btn"
          onClick={() => closeModal()}
          type="button"
        >
          Cancel
        </button>
        <div className={style.secondaryFormAction}>
          <span>Or call us</span>
          <a
            className="btn btn-text-field"
            href={`tel:${process.env.NEXT_PUBLIC_EVOLVE_SUPPORT_TELEPHONE_NUMBER}`}
            id="ask_a_question_call_link"
          >
            {process.env.NEXT_PUBLIC_EVOLVE_SUPPORT_TELEPHONE_NUMBER}
          </a>
        </div>
      </div>
    </form>
  )
}

export default QuestionForm
