import styles from './PropertyId.module.scss'

type PropertyIdProps = {
  id: string
}

const PropertyId = ({ id }: PropertyIdProps) => {
  return <span className={styles.main}>Property ID: {id}</span>
}

export default PropertyId
