import React from 'react'
import Image from 'next/image'

import { useDispatch, useSelect } from 'store/index'

import { toggleModal, updateModalView } from 'reducers/resultDetail'

import { AffirmMessage } from 'components/Affirm/AffirmMessage'
import StarRatings from 'components/StarRatings/StarRatings'
import FavoriteButton from 'components/Favorites/FavoriteButton'

import style from './Detail-Overview.module.scss'
import ShareModal from './Share-Modal'
import PropertyId from './ListingPage/PropertyId/PropertyId'

import { LargeTabletBreakpoint } from 'config/Breakpoints'

import { calcRating } from 'utils/Strings'
import { scrollToId } from 'utils/scroll'
import type { CombinedListing } from 'utils/staticData'

// Images
import mapButton from 'assets/images/mapButton.png'
import Share from 'assets/icons/icon-share.svg'

import { QuoteResponse } from 'types/externalData'

type DetailOverviewProps = {
  listing: CombinedListing
  detailMenuRefs: React.MutableRefObject<Record<string, HTMLElement | null>>
  shareModalOpen: boolean
  setShareModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  avgPrice?: number
  quote?: QuoteResponse | null
}

const DetailOverview: React.FC<DetailOverviewProps> = ({
  listing,
  detailMenuRefs,
  shareModalOpen,
  setShareModalOpen,
  avgPrice,
  quote,
}) => {
  // Redux Selectors
  const width = useSelect((state) => state.uiState.width)

  // Redux Actions
  const appDispatch = useDispatch()

  const handleMapButtonClick = () => {
    appDispatch(toggleModal())
    appDispatch(updateModalView('map'))
  }

  const handleShare = (e) => {
    e.stopPropagation()
    setShareModalOpen(true)
    document.body.classList.add('noScroll')
  }

  const getHeadlineFromAdContent = () => {
    const headline = listing['adContent'].filter(
      ({ type }) => type === 'headline',
    )

    if (
      headline.length &&
      'text' in headline[0] &&
      headline[0]['text'] !== 'null'
    ) {
      return headline[0]['text']
    }

    return false
  }

  return (
    <div
      className={style.detailOverview}
      ref={(el) => (detailMenuRefs.current['overview'] = el)}
    >
      {shareModalOpen && (
        <ShareModal
          listing={listing}
          setShareModalOpen={setShareModalOpen}
          styleGuide={false}
        />
      )}
      {listing && (
        <>
          <div className={style.detailOverviewTop}>
            {'Property Type' in listing ? (
              <span className={style.resultType}>
                {listing['Property Type']}
              </span>
            ) : 'units' in listing && listing.units.length ? (
              <span className={style.resultType}>
                {listing['units'][0]['type']}
              </span>
            ) : null}
            {width <= 600 && listing['Number of Reviews'] ? (
              <div className={style.resultDetailRating}>
                {'Average Rating' in listing && (
                  <div className={style.starRating}>
                    <span>{calcRating(listing['Average Rating']!)}</span>
                    <StarRatings
                      numberOfStars={5}
                      rating={calcRating(listing['Average Rating']!)}
                      starDimension="15px"
                      starRatedColor="#F9A01F"
                      starSpacing="1px"
                      uniqueId="resultDetailRatingMobile"
                    />
                  </div>
                )}
                {listing && 'Number of Reviews' in listing && (
                  <button
                    className={style.reviews}
                    onClick={() => scrollToId('reviews')}
                  >
                    {Math.round(listing['Number of Reviews']) <= 1
                      ? Math.round(listing['Number of Reviews']) + ' review'
                      : Math.round(listing['Number of Reviews']) + ' reviews'}
                  </button>
                )}
              </div>
            ) : null}
            {width > 600 && (
              <div className={style.detailOverviewButtons}>
                <FavoriteButton
                  container="detailOverview"
                  listing={listing}
                  quoteAvgPrice={avgPrice}
                />
                <button
                  className={style.detailOverviewBtn}
                  id="listing_share_btn"
                  onClick={handleShare}
                >
                  <Share height={20} width={16} />
                  Share
                </button>
              </div>
            )}
          </div>

          {listing?.['meta']?.['heading'] || listing?.['Headline'] ? (
            <h1>{listing?.['meta']?.['heading'] || listing?.['Headline']}</h1>
          ) : (
            getHeadlineFromAdContent() && <h1>{getHeadlineFromAdContent()}</h1>
          )}
          <div className={style.detailOverviewBottom}>
            <div>
              <div className={style.resultLocation}>
                <PropertyId id={`${listing.objectID}`} />
                {'City' in listing &&
                'State' in listing &&
                'Country' in listing ? (
                  <span>
                    {listing['City']},&nbsp;
                    {listing['State']},&nbsp;
                    {listing['Country']}
                  </span>
                ) : 'units' in listing && listing.units.length ? (
                  <span>
                    {listing['units'][0]['addresses'][0]['city']}
                    ,&nbsp;
                    {listing['units'][0]['addresses'][0]['stateShort']}
                    &nbsp;
                    {listing['units'][0]['addresses'][0]['countryShort'] !==
                      'US' && listing['units'][0]['addresses'][0]['country']}
                  </span>
                ) : null}
              </div>
              <div className={style.resultDetailInfo}>
                {'Max Occupancy' in listing &&
                'Bedrooms' in listing &&
                'Bathrooms' in listing ? (
                  <>
                    <span>
                      {width > 600
                        ? `Sleeps ${listing['Max Occupancy']} guests`
                        : `Sleeps ${listing['Max Occupancy']}`}
                    </span>
                    <span>
                      {width > 600
                        ? `${listing['Bedrooms']} Bedrooms`
                        : `${listing['Bedrooms']} BR`}
                    </span>
                    <span>
                      {width > 600
                        ? `${listing['Bathrooms']} Bathrooms`
                        : `${listing['Bathrooms']} BA`}
                    </span>
                  </>
                ) : 'units' in listing && listing.units.length ? (
                  <>
                    <span>
                      {width > 600
                        ? `Sleeps ${listing['units'][0]['configuration']['sleeps']} guests`
                        : `Sleeps ${listing['units'][0]['configuration']['sleeps']}`}
                    </span>
                    <span>
                      {width > 600
                        ? `${listing['units'][0]['configuration']['bedrooms']} Bedrooms`
                        : `${listing['units'][0]['configuration']['bedrooms']} BR`}
                    </span>
                    <span>
                      {width > 600
                        ? `${listing['units'][0]['configuration']['bathrooms']} Bathrooms`
                        : `${listing['units'][0]['configuration']['bathrooms']} BA`}
                    </span>
                  </>
                ) : null}
              </div>
            </div>
            {width > LargeTabletBreakpoint ? (
              <button
                className={style.mapButton}
                id="listing_map_btn"
                onClick={() => scrollToId('map')}
              >
                <Image
                  alt="Map Button"
                  height={100}
                  loading={'eager'}
                  src={mapButton}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                  width={100}
                />
              </button>
            ) : (
              <button
                className={style.mapButton}
                id="listing_map_btn"
                onClick={handleMapButtonClick}
              >
                <Image
                  alt="Map Button"
                  height={100}
                  loading={'eager'}
                  src={mapButton}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                  width={100}
                />
              </button>
            )}
          </div>
          <div className={style.affirmMobileMessage}>
            <AffirmMessage isMobile total={quote?.price?.total} />
          </div>
          {width <= 600 && (
            <div className={style.detailOverviewButtons}>
              <button className={style.detailOverviewBtn} onClick={handleShare}>
                <Share height={20} width={16} />
                Share
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default DetailOverview
