import React from 'react'

import style from './Accessibility.module.scss'

import type { CombinedListing } from 'utils/staticData'

import Check from 'assets/icons/icon-checkmark.svg'

export type AccessibilityProps = {
  listing: CombinedListing
}

const Accessibility: React.FC<AccessibilityProps> = ({ listing }) => {
  let accessibilities: string[] = []
  if (listing.amenities?.Accessibility) {
    accessibilities = listing['amenities']['Accessibility']
  } else if (listing.units?.length) {
    accessibilities = listing['units'][0]['amenities'].filter(
      ({ category }) => category === 'Accessibility',
    )
  }

  return (
    <div className={style.accessibility}>
      <h2 className={`section-subtitle-sm bold`}>Accessibility</h2>
      {accessibilities.map((accessibility, i) => {
        const name =
          typeof accessibility === 'string'
            ? accessibility
            : accessibility['name']

        return (
          <div className={style.accessItem} key={i}>
            <Check height={14} width={13} />
            <span>{name}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Accessibility
