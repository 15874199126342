import React, { useState } from 'react'

import { useDispatch } from 'store/index'

import { toggleReviewModal } from 'reducers/resultDetail'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import StarRatings from 'components/StarRatings/StarRatings'

import style from './Reviews.module.scss'

import Pagination from '../Pagination/Review-Pagination'

import type { CombinedListing } from 'utils/staticData'
import { calcRating } from 'utils/Strings'
import formatPublishDate from 'utils/dates/formatPublishDate'

import type { Review } from 'types/externalData'

const postsPerPage = 5

type ReviewsProps = {
  listing: CombinedListing
  listingReviews: Review[] | null
  detailMenuRefs: React.MutableRefObject<Record<string, HTMLElement | null>>
}

const Reviews: React.FC<ReviewsProps> = ({
  listing,
  listingReviews,
  detailMenuRefs,
}) => {
  const router = useAugmentedRouter()

  const maxRating = 5
  const [page, setPage] = useState(1)

  // Redux Actions
  const appDispatch = useDispatch()

  const totalPosts = listingReviews?.length
  const totalPages = totalPosts && Math.ceil(totalPosts / postsPerPage)

  const paginatedListingReviews = listingReviews?.slice(
    (page - 1) * postsPerPage,
    page * postsPerPage,
  )

  const btnClick = () => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          slug: listing.objectID,
          review_modal: 'open',
        },
      },
      undefined,
      { shallow: true },
    )

    appDispatch(toggleReviewModal())

    document.body.classList.add('noScroll')
  }

  return listingReviews && listingReviews.length ? (
    <div
      className={style.reviews}
      id="reviews"
      ref={(el) => (detailMenuRefs.current['reviews'] = el)}
    >
      <h2 className={`section-subtitle`}>Reviews</h2>
      {listing?.['Average Rating'] && listing['Number of Reviews'] ? (
        <div className={style.reviewOverview}>
          <span>{calcRating(listing['Average Rating'])}</span>
          <StarRatings
            numberOfStars={maxRating}
            rating={calcRating(listing['Average Rating'])}
            starDimension="20px"
            starRatedColor="#F9A01F"
            starSpacing="0px"
            uniqueId="reviewOverview"
          />
          <span>{`${listing['Number of Reviews']} ${
            listing['Number of Reviews'] > 1 ? 'reviews' : 'review'
          }`}</span>
        </div>
      ) : null}
      <button
        className={`btn-secondary ${style.writeReview}`}
        id="write_review_btn"
        onClick={btnClick}
      >
        Write a Review
      </button>
      <ul className={style.reviewsList}>
        {paginatedListingReviews?.map((review, i) => {
          return (
            <li key={i}>
              <h4>{review.reviewSummary}</h4>
              <header>
                <span className="reviewRateListing">{`${review.rating}/${maxRating}`}</span>
                <StarRatings
                  numberOfStars={maxRating}
                  rating={calcRating(review.rating)}
                  starDimension="16px"
                  starRatedColor="#F9A01F"
                  starSpacing="1px"
                  uniqueId={`reviewListItem${i}`}
                />
              </header>
              {review.reviewDetail && (
                <div className={style.reviewBody}>
                  {review.reviewDetail.split(/<br>/).map((item, i) => {
                    return <p key={i}>{item}</p>
                  })}
                </div>
              )}
              <footer>
                <h5>{review.reviewedBy}</h5>
                <span>{`Published ${formatPublishDate(
                  review.createdDt,
                )}`}</span>
              </footer>
              {review.reviewResponse && (
                <div className={style.reviewResponse}>
                  <h5>Manager&apos;s Response:</h5>
                  <p
                    dangerouslySetInnerHTML={{ __html: review.reviewResponse }}
                  />
                </div>
              )}
            </li>
          )
        })}
      </ul>
      <Pagination
        onPageChange={(page: number) => {
          setPage(page)

          setTimeout(() => {
            detailMenuRefs.current['reviews'] &&
              window.scrollTo({
                behavior: 'smooth',
                top:
                  detailMenuRefs.current['reviews'].getBoundingClientRect()
                    .top + window.pageYOffset,
              })
          })
        }}
        page={page}
        totalPages={totalPages}
      />
    </div>
  ) : (
    <div
      className={style.reviews}
      id="reviews"
      ref={(el) => (detailMenuRefs.current['reviews'] = el)}
    >
      <h2 className={`section-subtitle`}>Reviews</h2>
      <p style={{ margin: '-1rem 0 1rem' }}>
        There are currently no reviews to display.
      </p>
      {listing &&
        'Average Rating' in listing &&
        'Number of Reviews' in listing && (
          <div className={style.reviewOverview}>
            <span>{0}</span>
            <StarRatings
              numberOfStars={maxRating}
              rating={0}
              starDimension="15px"
              starRatedColor="#F9A01F"
              starSpacing="2px"
              uniqueId="noReviews"
            />
            <span>0 reviews</span>
          </div>
        )}
      <button
        className={`btn-secondary ${style.writeReview}`}
        onClick={btnClick}
      >
        Write a Review
      </button>
    </div>
  )
}

export default Reviews
