import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import { BookingContext } from 'context/BookingContext'
import { ConsentContext } from 'context/ConsentContext'

import UrgencyMessage from '../UrgencyMessage'

import ToastUtils from 'utils/toastUtils'

type UrgencyPriceProps = {
  avgPrice?: number
  nextDatesAvgPrice?: number
  isMobile?: Boolean
}

const belowAvgThresholdPercent = 10

const UrgencyPrice = ({
  avgPrice,
  nextDatesAvgPrice,
  isMobile,
}: UrgencyPriceProps) => {
  const toastId = useRef<string | number | null>(null)

  const { updateTestData, dates } = useContext(BookingContext)

  const belowAvgPercent = useMemo(() => {
    if (!nextDatesAvgPrice || !avgPrice) return 0
    if (nextDatesAvgPrice < avgPrice) return 0
    return +Math.round(
      (100 / nextDatesAvgPrice) * (nextDatesAvgPrice - avgPrice),
    ).toFixed()
  }, [avgPrice, nextDatesAvgPrice])

  const triggerEvent = useCallback(
    (testVariant, testPercent) => {
      updateTestData(testVariant, testPercent)
    },
    [updateTestData],
  )

  const isBelowTreshold = belowAvgPercent < belowAvgThresholdPercent

  const {
    state: { showConsentModal },
  } = useContext(ConsentContext)

  useEffect(() => {
    if (!dates || !dates?.start || !dates?.end) return
    if (showConsentModal) return
    if (isBelowTreshold) {
      if (!dates || nextDatesAvgPrice) {
        triggerEvent('control', 0)
      }
      return
    }
    if (isMobile) {
      return
    }

    triggerEvent('Price Urgency', belowAvgPercent)
    toastId.current = ToastUtils.openToast(
      <UrgencyMessage percentage={belowAvgPercent} type="price" />,
      { autoClose: 7000 },
    )

    return () => {
      if (toastId.current) {
        ToastUtils.dismissToastById(toastId.current)
        toastId.current = null
      }
    }
    // No need to add triggerEvent to deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isBelowTreshold, belowAvgPercent, showConsentModal])

  const urgencyPriceMessage = useMemo(() => {
    if (isBelowTreshold) {
      if (!dates || nextDatesAvgPrice) {
        triggerEvent('control', 0)
      }
      return null
    }
    if (!isMobile) {
      return null
    }
    triggerEvent('Price Urgency', belowAvgPercent)
    return <UrgencyMessage percentage={belowAvgPercent} type="price" />
    // No need to add triggerEvent to deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isBelowTreshold, belowAvgPercent])

  return urgencyPriceMessage
}

export default UrgencyPrice
