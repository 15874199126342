const debounce = (
  callback: (...args: any[]) => any,
  delay: number,
  immediate = false,
) => {
  let timeoutId: NodeJS.Timeout
  return (...args: any[]) => {
    const callNow = immediate && !timeoutId
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      callback(...args)
    }, delay)

    if (callNow) {
      callback(...args)
    }
  }
}

export default debounce
