import React from 'react'

import style from './Limited-Text-Area.module.scss'

const LimitedTextarea = ({ limit, name, hasErrors }, ref) => {
  const [content, setContent] = React.useState('')

  const setFormattedContent = React.useCallback(
    (text) => {
      setContent(text.slice(0, limit))
    },
    [limit, setContent],
  )
  const characterLimitCheck = content.length >= limit ? true : false

  return (
    <div className={style.limitedTextAreaWrapper}>
      <p
        className={`${style.characterCounter} ${
          characterLimitCheck && style.error
        }`}
      >
        {content.length.toLocaleString()}/{limit.toLocaleString()}
      </p>
      <textarea
        className={`${characterLimitCheck || hasErrors ? 'error' : ''}`}
        maxLength={limit}
        name={name}
        onChange={(event) => {
          setFormattedContent(event.target.value)
        }}
        ref={ref}
      />
      {characterLimitCheck && <span>*You've reached our character limit</span>}
    </div>
  )
}

export default React.forwardRef(LimitedTextarea)
