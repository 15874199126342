import React, { ReactElement } from 'react'

import styles from './UrgencyMessage.module.scss'

import PriceTag from 'assets/icons/PriceTag.svg'
import HeartInHouse from 'assets/icons/HeartInHouse.svg'

export type UrgencyMessageType = 'price' | 'availability'

type UrgencyMessageProps = {
  percentage: number
  type: UrgencyMessageType
}

export const TypeMap: Record<
  UrgencyMessageType,
  { icon: ReactElement; message(number): ReactElement }
> = {
  price: {
    icon: <PriceTag height={35} width={35} />,
    message: (percentage) => (
      <>
        This price is <b>{percentage}% less</b> than average over the next 120
        days.
      </>
    ),
  },
  availability: {
    icon: <HeartInHouse height={35} width={35} />,
    message: (percentage) => (
      <>
        This property is <b>{percentage}% booked</b> for this month, book now to
        reserve your dates.
      </>
    ),
  },
}

const UrgencyMessage = ({ percentage, type }: UrgencyMessageProps) => {
  const typeConfig = TypeMap[type]
  const icon = typeConfig.icon
  const message = typeConfig.message(percentage)
  return (
    <div className={styles.messageContent}>
      {icon}
      <div>{message}</div>
    </div>
  )
}

export default UrgencyMessage
