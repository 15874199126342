function formatTime(time: string) {
  const regex = /^(\d+):\d+\s(AM|PM)$/
  const match = time.match(regex)
  if (match) {
    const [, hour, ampm] = match
    return ` ${hour} ${ampm} `
  }
  return time
}

export default formatTime
