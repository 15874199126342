// Todo: Refactor

import React from 'react'

import style from './Form-Messaging.module.scss'

import CustomLink from '../Link/CustomLink'

const FormMessaging = ({
  handleBookNow,
  handleBack,
  handleExplore,
  success,
  question,
}) => {
  return (
    <div className={style.success}>
      <h4>
        {success
          ? question
            ? 'Lock This Home in Now'
            : 'Thank you!'
          : 'Sorry'}
      </h4>
      <p>
        {success && !question
          ? `Your review has been received and will appear
          once it has been verified.`
          : success && question
          ? `We'll email you with more information soon — but book this place now before another traveler gets the same idea. Evolve offers flexibility to change your plans when you book with us directly on our site.`
          : `We are experiencing some issues,
          please try again later.`}
      </p>
      <div className={style.successBtns}>
        {success && question ? (
          <button className="btn-primary" onClick={handleBookNow}>
            Book Now
          </button>
        ) : (
          <button className="btn-secondary" onClick={handleBack}>
            Back To Listing
          </button>
        )}
        <CustomLink
          className={`btn-${success && question ? 'secondary' : 'primary'}`}
          href="/vacation-rentals/search"
          onClick={handleExplore}
          title="Explore More Homes"
        />
      </div>
    </div>
  )
}

export default FormMessaging
