import React, { useRef, useState, useEffect, useContext } from 'react'
import dayjs from 'dayjs'
import type { ReCAPTCHAProps } from 'react-google-recaptcha'

import { useDispatch } from 'store/index'

import { toggleReviewModal } from 'reducers/resultDetail'

import { PageHistoryContext } from 'context/PageHistoryContext'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import ListingOverview from './ListingOverview'
import ReviewForm from './Review-Form'
import style from './Review-Modal.module.scss'

import FormMessaging from '../Forms/Form-Messaging'

import type { CombinedListing } from 'utils/staticData'

import CloseIcon from 'assets/icons/icon-close.svg'

import type { AlgoliaListing } from 'types/externalData'

type ReviewModalProps = {
  listing: CombinedListing
}

const ReviewModal: React.FC<ReviewModalProps> = ({ listing }) => {
  const router = useAugmentedRouter()

  const reviewModal = useRef(null)
  const [formStatus, setFormStatus] = useState<'success' | 'error' | null>(null)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const [recaptchaError, setRecaptchaError] = useState(true)

  const { pageHistory } = useContext(PageHistoryContext)

  // Redux Actions
  const appDispatch = useDispatch()

  const removeModalURL = () => {
    delete router.query.review_modal

    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          slug: listing.objectID,
        },
      },
      undefined,
      { shallow: true },
    )
  }

  const closeModal = () => {
    removeModalURL()
    appDispatch(toggleReviewModal())
    document.body.classList.remove('noScroll')
  }

  const handleSubmit = async (data) => {
    try {
      const body = JSON.stringify({
        ...data,
        listingId: listing.objectID,
        reviewSource: 'Website',
        travelDate: dayjs(data.travelDate).format('YYYY-MM-DD'),
        recaptchaToken,
      })
      const headers = { 'Content-Type': 'application/json' }
      const response = await fetch('/api/reviews', {
        method: 'POST',
        body,
        headers,
      })

      setFormStatus(response.ok ? 'success' : 'error')
    } catch (err) {
      setFormStatus('error')
    }
  }

  const recaptchaChange: ReCAPTCHAProps['onChange'] = async (token) => {
    setRecaptchaToken(token)
    if (token === null) {
      setRecaptchaError(true)
      return
    }

    setRecaptchaError(false)
  }

  const handleBack = () => {
    removeModalURL()
    appDispatch(toggleReviewModal())
    document.body.classList.remove('noScroll')
    setFormStatus(null)
  }

  const handleExplore = () => {
    removeModalURL()
    appDispatch(toggleReviewModal())
    document.body.classList.remove('noScroll')
    setFormStatus(null)
  }

  useEffect(() => {
    window.analytics.page('Leave a Review', {
      title: 'Leave a Review',
      referrer: pageHistory[pageHistory.length - 1],
    })
  }, [pageHistory])

  return (
    <div className="modalWrapper fullWhite" ref={reviewModal}>
      <div className="modal">
        <div className={`modalTop ${style.reviewModalDesktopTop}`}>
          <h4 className={`blue mobileHeading`}>Write a Review</h4>
          <button
            className="modalClose responsiveCloseIcon"
            onClick={closeModal}
          >
            <CloseIcon width={11} />
          </button>
        </div>
        <div className={`modalBody`}>
          <h4 className={`blue desktopHeading ${style.boldHeading}`}>
            Write a Review
          </h4>
          <div className={style.reviewModalBody}>
            {formStatus === 'error' && (
              <FormMessaging
                handleBack={handleBack}
                handleBookNow={null}
                handleExplore={handleExplore}
                question={null}
                success={null}
              />
            )}
            {formStatus === 'success' && (
              <FormMessaging
                handleBack={handleBack}
                handleBookNow={null}
                handleExplore={handleExplore}
                question={null}
                success
              />
            )}
            {formStatus === null && (
              <>
                <ListingOverview
                  listing={listing as unknown as AlgoliaListing}
                />
                <ReviewForm
                  closeModal={closeModal}
                  onSubmit={handleSubmit}
                  recaptchaChange={recaptchaChange}
                  recaptchaError={recaptchaError}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewModal
