import classNames from 'classnames'

import Arrow from 'components/Carousels/Arrow'

import styles from './BaseCarousel.module.scss'

export const PrevButton = ({ enabled, onClick, autoHide }) => (
  <Arrow
    className={classNames(styles.embla__button, styles.embla__button__prev, {
      [styles.embla__button__disabled]: !enabled,
      [styles.embla__button__auto_hide]: autoHide,
    })}
    dark={true}
    direction="left"
    onClick={onClick}
  />
)

export const NextButton = ({ enabled, onClick, autoHide }) => (
  <Arrow
    className={classNames(styles.embla__button, styles.embla__button__next, {
      [styles.embla__button__disabled]: !enabled,
      [styles.embla__button__auto_hide]: autoHide,
    })}
    dark={true}
    direction="right"
    onClick={onClick}
  />
)
