import { useCallback, useEffect, useMemo, useRef, useContext } from 'react'

import { BookingContext } from 'context/BookingContext'

import UrgencyMessage from '../UrgencyMessage'

import ToastUtils from 'utils/toastUtils'

type UrgencyAvailabilityProps = {
  isMobile?: boolean
  capacityBookedPercentage: number
}

const UrgencyAvailability = ({
  capacityBookedPercentage,
  isMobile,
}: UrgencyAvailabilityProps) => {
  const toastId = useRef<string | number | null>(null)

  const { updateTestData } = useContext(BookingContext)

  const triggerEvent = useCallback(() => {
    updateTestData('Availability Urgency', capacityBookedPercentage)
  }, [updateTestData, capacityBookedPercentage])

  useEffect(() => {
    if (isMobile) {
      return
    }

    toastId.current = ToastUtils.openToast(
      <UrgencyMessage
        percentage={capacityBookedPercentage}
        type="availability"
      />,
      { autoClose: 7000 },
    )
    triggerEvent()

    return () => {
      if (toastId.current) {
        ToastUtils.dismissToastById(toastId.current)
        toastId.current = null
      }
    }
    // No need to add triggerEvent to deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, capacityBookedPercentage])

  const belowAvgPriceMessage = useMemo(() => {
    if (!isMobile) {
      return null
    }

    triggerEvent()
    return (
      <UrgencyMessage
        percentage={capacityBookedPercentage}
        type="availability"
      />
    )
    // No need to add triggerEvent to deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, capacityBookedPercentage])

  return belowAvgPriceMessage
}

export default UrgencyAvailability
