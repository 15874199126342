import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { useSelect } from 'store/index'
import { searchSelectors } from 'store/search'

import urlToSearchState from 'utils/search/urlToSearchState'
import { searchStateToUrl } from 'utils/Search'
import getSearchStateFromAppState from 'utils/search/getSearchStateFromAppState'
import type { CombinedListing } from 'utils/staticData'

const useSearchUrl = (listing: CombinedListing) => {
  const location = `${listing.City}, ${listing.State}, ${listing.Country}`
  const state = useSelect(searchSelectors.selectSearchSlice)
  const router = useRouter()

  const searchUrl = useMemo(() => {
    const { guests, dates } = urlToSearchState(router.asPath)
    const searchState = getSearchStateFromAppState({
      ...state,
      query: location,
    })
    const query = searchStateToUrl({ ...searchState, guests, dates })

    return `/vacation-rentals/search${query}`
  }, [router, state, location])

  return searchUrl
}

export default useSearchUrl
