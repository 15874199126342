import { memo } from 'react'
import { enableBodyScroll } from 'body-scroll-lock'
import { useDispatch } from 'react-redux'

import { openQuestionModal } from 'reducers/resultDetail'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import styles from './AskQuestion.module.scss'

type AskQuestionProps = {
  listingId: number
  bookingRef: any
}

const AskQuestion = ({ listingId, bookingRef }: AskQuestionProps) => {
  const router = useAugmentedRouter()
  const appDispatch = useDispatch()

  const onClick = () => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          slug: listingId,
          inquiry_modal: 'open',
        },
      },
      undefined,
      { shallow: true },
    )

    bookingRef.current && enableBodyScroll(bookingRef.current)

    appDispatch(openQuestionModal())
    document.body.classList.add('noScroll')
  }

  return (
    <div className={styles.main}>
      <div>
        <h2>Still have questions?</h2>
        <p>Reach out help with general questions about the property.</p>
      </div>
      <button id="askAQuestion" onClick={onClick}>
        Ask a Question
      </button>
    </div>
  )
}

export default memo(AskQuestion)
