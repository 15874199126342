import { isBelowViewportWidth } from 'selectors/uiState'

import type { BookingDates } from 'context/BookingContext'

import type { AvailabilitySummary } from 'hooks/useListingAvailability'

import useNextDaysAvgPrice from './useNextDaysAvgPrice'
import useOverCapacity from './useOverCapacity'
import UrgencyAvailability from './UrgencyAvailability'
import UrgencyPrice from './UrgencyPrice'

import { SmallTabletBreakpoint } from 'config/Breakpoints'

import { useSelect } from 'store'

export type BookingUrgencyProps = {
  availability: AvailabilitySummary
  bookingDates: BookingDates
  avgPrice?: number
}

const BookingUrgency = ({
  availability,
  bookingDates,
  avgPrice,
}: BookingUrgencyProps) => {
  const isMobile = useSelect((state) =>
    isBelowViewportWidth(state, SmallTabletBreakpoint),
  )
  const { nextDatesAvgPrice } = useNextDaysAvgPrice({
    availability,
  })
  const { capacityBookedPercentage: bookedPercentace, isOverCapacity } =
    useOverCapacity({
      availability,
      bookingDates,
    })

  return isOverCapacity ? (
    <UrgencyAvailability
      capacityBookedPercentage={bookedPercentace}
      isMobile={isMobile}
    />
  ) : (
    <UrgencyPrice
      avgPrice={avgPrice}
      isMobile={isMobile}
      nextDatesAvgPrice={nextDatesAvgPrice}
    />
  )
}

export default BookingUrgency
