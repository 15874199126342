import React, { useState } from 'react'
import Image from 'next/image'
import dynamic from 'next/dynamic'

import { useSelect, useDispatch } from 'store/index'

import { toggleModal } from 'reducers/resultDetail'

import { useImageLoader } from 'hooks/useImageLoader'

import style from './Image-Gallery.module.scss'

import getListingCloudinaryId from 'utils/strings/getListingCloudinaryId'
import getCloudinaryPlaceholder from 'utils/strings/getCloudinaryPlaceholder'

import ComingSoon from 'assets/icons/Camera_Filled.svg'
import CameraIcon from 'assets/icons/icon-camera.svg'

// Dynamic Components
const ImageGalleryModal = dynamic(() => import('./Image-Gallery-Modal'))

type ImageGalleryProps = {
  images: any[]
  location: { lat: number; lng: number }
  headline: string | null
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  location,
  headline,
}) => {
  const [imageIndex, setImageIndex] = useState(0)
  const [loadAllImages, setLoadAllImages] = useState(false)
  const imageLoader = useImageLoader()

  // Redux Selectors
  const openModal = useSelect((state) => state.resultDetail.openModal)

  // Redux Actions
  const appDispatch = useDispatch()

  const handleOpenModal = (index: number) => {
    setImageIndex(index)
    appDispatch(toggleModal())
  }

  return (
    <div
      className={
        images && images.length ? style.gallery : style.galleryNoImages
      }
    >
      {openModal && (
        <ImageGalleryModal
          headline={headline}
          imageIndex={imageIndex}
          images={images}
          location={location}
        />
      )}

      {images && images.length ? (
        <>
          <button
            className={`${style.photoIndicator} imageGalleryTrigger`}
            id="photo_gallery_btn"
            onClick={() => handleOpenModal(0)}
          >
            <CameraIcon />
            <span className={style.photoTotalDesktop}>
              {`View all ${images.length} images`}
            </span>
          </button>
          <div className={style.listingPhotosContainer}>
            {images
              .slice(0, 4)
              .map(({ URL, url, Caption, caption, alt: imageAltText }, i) => {
                const altText = imageAltText || Caption || caption

                return (
                  <div
                    className={`image ${style.clickablePhoto} imageGalleryTrigger`}
                    key={i}
                    onClick={() => handleOpenModal(i)}
                  >
                    <Image
                      alt={altText}
                      blurDataURL={getCloudinaryPlaceholder(
                        getListingCloudinaryId(URL ?? url),
                      )}
                      className={style.clickablePhoto}
                      layout="fill"
                      loader={imageLoader}
                      loading={loadAllImages ? 'eager' : undefined}
                      objectFit="cover"
                      onTouchStart={() => setLoadAllImages(true)}
                      placeholder={'blur'}
                      priority={i === 0}
                      sizes="33vw"
                      src={getListingCloudinaryId(URL ?? url)}
                    />
                  </div>
                )
              })}
          </div>
        </>
      ) : (
        <div className={style.comingSoon}>
          <ComingSoon className={style.comingSoonImg} />
          <span>Coming Soon!</span>
        </div>
      )}
    </div>
  )
}

export default ImageGallery
