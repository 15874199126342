import { useMemo } from 'react'
import dayjs from 'dayjs'

import type { AvailabilitySummary } from 'hooks/useListingAvailability'

import DatesConstants from 'constants/dates'

type UseNextDaysAvgPriceProps = {
  availability: AvailabilitySummary
  nextDatesAvgLenght?: number
}

type UseNextDaysAvgPriceResponse = {
  nextDatesAvgPrice: number
}

const useNextDaysAvgPrice = ({
  availability,
  nextDatesAvgLenght = 120,
}: UseNextDaysAvgPriceProps): UseNextDaysAvgPriceResponse => {
  const { pricePerDay } = availability

  const nextDatesAvgPrice = useMemo(() => {
    const today = dayjs().format(DatesConstants.DEFAULT)
    const datePrices = Object.keys(pricePerDay)
    const datePriceTodayIndex = datePrices.indexOf(today)
    const nextDatesFromToday = datePrices.slice(
      datePriceTodayIndex,
      datePriceTodayIndex + nextDatesAvgLenght,
    )
    return +(
      nextDatesFromToday.reduce((acc, date) => acc + pricePerDay[date], 0) /
      nextDatesAvgLenght
    ).toFixed()
  }, [pricePerDay, nextDatesAvgLenght])

  return {
    nextDatesAvgPrice,
  }
}

export default useNextDaysAvgPrice
