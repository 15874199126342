//TODO: This needs heavy rework
import React, {
  useContext,
  useState,
  useEffect,
  useReducer,
  useRef,
  SetStateAction,
} from 'react'
import dayjs from 'dayjs'
import type { ReCAPTCHAProps } from 'react-google-recaptcha'

import { useSelect, useDispatch } from 'store/index'

import { openQuestionModal, closeQuestionModal } from 'reducers/resultDetail'

import { BookingContext } from 'context/BookingContext'
import { PageHistoryContext } from 'context/PageHistoryContext'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import FormMessaging from 'components/Forms/Form-Messaging'
import { CancellationAlert } from 'components/CancellationAlert/CancellationAlert'
import Modal from 'components/Modal/Modal'
import { CancellationPolicy } from 'components/CancellationPolicy/CancellationPolicy'

import style from './QuestionModal.module.scss'

import QuestionForm from '../Question-Form'
import Quote from '../ListingPage/Quote'
import { ListingAmenities } from '../ListingPage/ListingPage.types'

import { DesktopBreakpoint, LargeTabletBreakpoint } from 'config/Breakpoints'

import { searchStateToUrl } from 'utils/Search'
import urlToSearchState from 'utils/search/urlToSearchState'
import { pushToDataLayer } from 'utils/Gtm'
import capitalize from 'utils/strings/capitalize'
import type { CombinedListing } from 'utils/staticData'
import getNestedValue from 'utils/getNestedValue'

import CloseIcon from 'assets/icons/icon-close.svg'
import CaretDown from 'assets/icons/icon-caretDown.svg'
import Check from 'assets/icons/icon-checkmark.svg'

import type { BoomiListing, Refunds } from 'types/externalData'

type ExpandableProps = {
  title: string
  items: string[]
  open: boolean
  onClick: (open: boolean) => void
}

const Expandable: React.FC<ExpandableProps> = ({
  title,
  items,
  open,
  onClick,
}) => (
  <div className={style.expandable}>
    <button className={style.expandableBtn} onClick={() => onClick(!open)}>
      {title}
      <CaretDown className={open ? style.flipCaret : undefined} />
    </button>
    {open && (
      <div>
        {items &&
          items.map((rule, i) => (
            <div className={`listItem ${style.rule}`} key={i}>
              <Check height={14} width={13} />
              <span>{rule}</span>
            </div>
          ))}
      </div>
    )}
  </div>
)

// State Reducer
const questionModalReducer: React.Reducer<any, any> = (state, action) => {
  switch (action.type) {
    case 'RECAPTCHA_SUCCESS':
      return {
        ...state,
        recaptchaError: false,
      }
    case 'RECAPTCHA_FAIL':
      return {
        ...state,
        recaptchaError: true,
      }
    case 'FORM_SUBMITTED':
      return {
        ...state,
        submitted: true,
        showError: true,
        formSuccess: true,
        formFields: {
          ...state.formFields,
          ...action.payload,
        },
      }
    case 'FORM_VERIFICATION_FAILED':
      return {
        ...state,
        submitted: false,
        formSuccess: false,
      }
    case 'MESSAGE_POSTED':
      return {
        ...state,
        success: action.payload,
        formError: !action.payload,
      }
    case 'BACK_CLICKED':
      return {
        formFields: {
          reviewSource: 'Website',
        },
        success: false,
      }
    case 'EXPLORE_CLICKED':
      return {
        formFields: {
          reviewSource: 'Website',
        },
        formError: false,
      }
    default:
      return state
  }
}

type QuestionModalProps = {
  country: string
  listing: CombinedListing
  availability?: any[]
  availabilityAvgPrice?: number
  minStay?: any
  viewDetailsOpen: boolean
  setViewDetailsOpen: React.Dispatch<SetStateAction<boolean>>
  amenities: Array<ListingAmenities>
  cancellationRefunds?: Refunds
  isLoadingQuote: boolean
  isValidatingQuote: boolean
  quoteData: any
  quoteError: any
  quoteErrorMessage: string
}

const QuestionModal: React.FC<QuestionModalProps> = ({
  country,
  listing,
  availabilityAvgPrice,
  minStay,
  viewDetailsOpen,
  setViewDetailsOpen,
  amenities,
  cancellationRefunds,
  isLoadingQuote,
  isValidatingQuote,
  quoteData,
  quoteError,
  quoteErrorMessage,
}) => {
  const router = useAugmentedRouter()
  const searchState = urlToSearchState(router.asPath)
  const { quote, bookingRef, guests, dates } = useContext(BookingContext)
  const { pageHistory } = useContext(PageHistoryContext)
  const [rulesOpen, setRulesOpen] = useState(false)
  const [houseRules, setHouseRules] = useState<string[]>([])
  const [bookingError, setBookingError] = useState(false)
  const [cancellationModalIsOpen, setCancellationModalIsOpen] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)

  const [state, dispatch] = useReducer(questionModalReducer, {
    formFields: {},
    success: false,
    formSuccess: false,
    formError: false,
    fetching: false,
    bookingError: false,
    recaptchaError: true,
    showError: false,
  })

  const questionModal = useRef<HTMLDivElement>(null)
  const recaptchaRef = useRef(null)

  // Redux Selectors
  const width = useSelect((state) => state.uiState.width)
  const selectedListings = useSelect(
    (state) => state.favorites.selectedListings,
  )

  // Redux Actions
  const appDispatch = useDispatch()

  useEffect(() => {
    const getHouseRules = () => {
      if (listing?.adContent) {
        const houseRules = (
          listing['adContent'] as BoomiListing['adContent']
        ).filter(({ type }) => type === 'houseRules')
        // Filter houseRules line breaks and remove markdown formatting
        const rulesArr = houseRules[0].text
          .match(/^(?!\w{1,}).*/gim)
          ?.reduce((validRules: string[], currentRule: string) => {
            if (currentRule) {
              validRules.push(currentRule.replace(/^-\s/gi, ''))
            }
            return validRules
          }, [])
        rulesArr && setHouseRules(rulesArr)
      }
    }
    if (listing) {
      getHouseRules()
    }
  }, [listing])

  // Show Errors
  useEffect(() => {
    if (state.showError) {
      if (bookingError) {
        dispatch({
          type: 'FORM_VERIFICATION_FAILED',
        })
      }
    }
  }, [
    bookingError,
    bookingRef,
    dates,
    guests,
    setViewDetailsOpen,
    state.showError,
    viewDetailsOpen,
    width,
    appDispatch,
  ])

  useEffect(() => {
    const postMessage = async () => {
      const traveler = {
        phone: state.formFields['phone'],
        lastName: state.formFields['lastName'],
        firstName: state.formFields['firstName'],
        externalUserID: 'FutureUse',
        emailAddress: state.formFields['email'],
        contactPreferance: 'email',
        emailOptOut: country === 'US' ? null : !state.formFields['subscribe'],
      }

      const thread = {
        treadID: 'FutureUse', // TODO: Fix this typo when the AWS migration is finished
        messageDetails: {
          messageID: 'FutureUse',
          message: state.formFields['message'],
        },
      }

      const reservation = {
        numberOfPets: quote?.['numberOfPets'],
        numberOfChildren: quote?.['numberOfChildren'],
        numberOfAdults: quote?.['numberOfAdults'],
        internalListingID: quote?.['internalListingID'],
        includeTravelInsurance: false,
        externalReservationID: 'FutureUse',
        checkOutDate: quote?.['checkOutDate'],
        checkInDate: quote?.['checkInDate'],
      }

      const distributor = {
        listingChannel: router.query.lc
          ? typeof router.query.lc === 'string'
            ? capitalize(router.query.lc as string)
            : capitalize(router.query.lc[0] as string)
          : 'Website',
        sessionid: router.query.sessionid
          ? getNestedValue(router.query.sessionid)
          : '',
        channel: 'Website',
      }

      if (listing?.objectID) {
        pushToDataLayer('submitInquiry', {
          email: state.formFields['email'],
        })

        try {
          const body = JSON.stringify({
            traveler,
            thread,
            reservation,
            distributor,
            listingId: listing.objectID,
            recaptchaToken,
          })
          const headers = { 'Content-Type': 'application/json' }
          const response = await fetch('/api/question', {
            method: 'POST',
            body,
            headers,
          })

          dispatch({
            type: 'MESSAGE_POSTED',
            payload: response.ok,
          })
        } catch (err) {
          dispatch({
            type: 'MESSAGE_POSTED',
            payload: false,
          })
        }
      }
    }

    if (state.formSuccess) {
      postMessage()
    }
  }, [
    quote,
    listing?.objectID,
    state.formFields,
    state.formSuccess,
    country,
    router.query.lc,
    router.query.sessionid,
  ])

  const removeModalURL = () => {
    delete router.query.inquiry_modal
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          slug: listing.objectID,
        },
      },
      undefined,
      { shallow: true },
    )
  }

  const closeModal = () => {
    appDispatch(closeQuestionModal())
    document.body.classList.remove('noScroll')
    removeModalURL()
  }

  const handleSubmit = async (data: any) => {
    dispatch({
      type: 'FORM_SUBMITTED',
      payload: data,
    })
  }

  const recaptchaChange: ReCAPTCHAProps['onChange'] = async (token) => {
    setRecaptchaToken(token)
    if (token === null) {
      dispatch({ type: 'RECAPTCHA_FAIL' })
      return
    }

    dispatch({ type: 'RECAPTCHA_SUCCESS' })
  }

  const handleBack = () => {
    removeModalURL()
    appDispatch(openQuestionModal())
    dispatch({
      type: 'BACK_CLICKED',
    })
    document.body.classList.remove('noScroll')
  }

  const handleExplore = () => {
    removeModalURL()
    appDispatch(closeQuestionModal())
    dispatch({
      type: 'EXPLORE_CLICKED',
    })
    document.body.classList.remove('noScroll')
  }

  const handleBookNow = () => {
    const { id } = router.query
    closeModal()
    if (id && searchState && quote && listing) {
      searchState['id'] = id
      const bookingRoute = searchStateToUrl(searchState)

      pushToDataLayer('add_to_cart', {
        ecommerce: {
          currency: 'USD',
          value: quote?.price?.total.toString(),
          items: [
            {
              item_id: id,
              item_name: listing.Headline,
              item_category: listing.units?.[0].type,
              price: quote.price.total,
              quantity: 1,
            },
          ],
        },
        favoriteListing: selectedListings.some(
          (favorite) => favorite.objectID === listing?.objectID,
        ),
      })

      pushToDataLayer('begin_checkout', {
        content_ids: [id],
        city: listing.City ? listing.City : 'multi',
        region: listing.State ? listing.State : 'multi',
        country: listing.Country ? listing.Country : 'multi',
        travel_start: dates?.start
          ? dayjs(dates.start).format('YYYY-MM-DD')
          : '',
        travel_end: dates?.end ? dayjs(dates.end).format('YYYY-MM-DD') : '',
        num_adults: guests?.adults ? guests.adults : '0',
        num_children: guests?.children ? guests.children : '0',
        value: quote?.price.total,
        currency: 'USD',
        ecommerce: {
          value: quote?.price.total,
          currency: 'USD',
          items: [
            {
              item_id: id,
              item_name: listing.Headline,
              item_category: listing.units?.[0].type,
              price: quote.price.total,
              quantity: 1,
            },
          ],
        },
      })
      router.push(`/booking/${listing.objectID}/summary${bookingRoute}`)
    }
  }

  useEffect(() => {
    window.analytics.page('Ask a Question', {
      title: 'Ask a Question',
      referrer: pageHistory[pageHistory.length - 1],
    })
  }, [pageHistory])

  const quoteComponent = (
    <Quote
      amenities={amenities}
      availabilityAvgPrice={availabilityAvgPrice}
      cancellationRefunds={cancellationRefunds}
      isLoadingQuote={isLoadingQuote}
      isValidatingQuote={isValidatingQuote}
      listing={listing}
      minStay={minStay}
      quoteData={quoteData}
      quoteError={quoteError}
      quoteErrorMessage={quoteErrorMessage}
      setViewDetailsOpen={setViewDetailsOpen}
      viewDetailsOpen={viewDetailsOpen}
    />
  )
  const isDesktop = width >= DesktopBreakpoint
  const isMobile = width <= LargeTabletBreakpoint
  const hasDates = !!(dates?.start && dates?.end)
  const hasCancellationPolicyData =
    !isLoadingQuote && !quoteError && !!cancellationRefunds
  const shouldRenderMobileCancellationAlert =
    isMobile && (hasCancellationPolicyData || !hasDates)

  return (
    <div className="modalWrapper fullWhite" ref={questionModal}>
      <div className="modal">
        <div className="modalTop">
          <h4 className={`blue mobileHeading`}>
            {state.success ? 'Thanks for Your Message!' : 'Ask a Question'}
          </h4>
          <button
            className="modalClose responsiveCloseIcon"
            onClick={closeModal}
          >
            <CloseIcon width={11} />
          </button>
        </div>
        <div className={`modalBody ${style.questionModalBody}`}>
          <h4 className={`blue desktopHeading`}>
            {state.success ? 'Thanks for Your Message!' : 'Ask a Question'}
          </h4>
          <div className={style.questionModalWrapper}>
            {state.formError ? (
              <FormMessaging
                handleBack={handleBack}
                handleBookNow={null}
                handleExplore={handleExplore}
                question={false}
                success={false}
              />
            ) : state.success ? (
              <FormMessaging
                handleBack={null}
                handleBookNow={handleBookNow}
                handleExplore={handleExplore}
                question
                success
              />
            ) : (
              <>
                <div className={style.questionBody}>
                  <span className={style.headline}>
                    {listing?.['Headline']}
                  </span>
                  {shouldRenderMobileCancellationAlert ? (
                    <CancellationAlert
                      className={style.cancellationAlert}
                      dates={dates}
                      onClick={() => setCancellationModalIsOpen(true)}
                      refunds={cancellationRefunds}
                    />
                  ) : null}
                  <Expandable
                    items={houseRules}
                    onClick={setRulesOpen}
                    open={rulesOpen}
                    title="Review House Rules"
                  />
                  <QuestionForm
                    closeModal={closeModal}
                    country={country}
                    onSubmit={handleSubmit}
                    quote={quote}
                    recaptchaChange={recaptchaChange} //in progress
                    recaptchaError={state.recaptchaError}
                    recaptchaRef={recaptchaRef}
                    submitted={state.submitted}
                  />
                </div>
                {isDesktop ? quoteComponent : null}
                {isMobile ? (
                  <Modal
                    className={style.cancellationPolicyModal}
                    isOpen={cancellationModalIsOpen}
                    onCloseClick={() => setCancellationModalIsOpen(false)}
                  >
                    <CancellationPolicy
                      dates={dates}
                      refunds={cancellationRefunds}
                    />
                  </Modal>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuestionModal
