import React, { useState, useRef, useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import dayjs from 'dayjs'
import type { CalendarProps } from 'react-calendar'

import CalendarDatePicker from 'components/Calendar/CalendarDatePicker'

import style from './ReviewDate.module.scss'

import CalendarIcon from 'assets/icons/icon-calendar.svg'

export type ReviewDateProps = {
  onChange: (...event: any[]) => void
  value: any
}

const today = new Date()

const isOutsideRange: CalendarProps['tileDisabled'] = ({ date }) =>
  dayjs(date).isAfter(today)

const ReviewDate: React.FC<ReviewDateProps> = ({ onChange, value }) => {
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [activeStartDate, setActiveStartDate] = useState(today)

  const calendarDialogRef = useRef<HTMLDivElement>(null)
  const calendarContainerRef = useRef<HTMLDivElement>(null)
  const datePickerRef = useRef<HTMLDivElement>(null)

  const handleDateChange = useCallback(
    (date) => {
      onChange(dayjs(date).format('MMM D, YYYY'))
      setCalendarOpen(false)
    },
    [onChange],
  )

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      e.stopPropagation()
      if (
        calendarContainerRef.current &&
        datePickerRef.current &&
        !calendarContainerRef.current.contains(e.target as Node) &&
        !datePickerRef.current.contains(e.target as Node)
      ) {
        setCalendarOpen(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
      setCalendarOpen(false)
    }
  }, [])

  return (
    <>
      <div className={style.singleDateInputContainer} ref={datePickerRef}>
        <input
          onClick={() => setCalendarOpen(true)}
          readOnly
          value={value}
        ></input>
        <CalendarIcon width={22} />
        <div className={style.calendarDialog} ref={calendarDialogRef}>
          {calendarDialogRef.current &&
            calendarOpen &&
            createPortal(
              <>
                <div
                  className={style.calendarContainer}
                  ref={calendarContainerRef}
                >
                  <CalendarDatePicker
                    activeStartDate={activeStartDate}
                    dateDisabled={isOutsideRange}
                    onChange={handleDateChange}
                    setActiveStartDate={setActiveStartDate}
                  />
                </div>
              </>,
              calendarDialogRef.current,
            )}
        </div>
      </div>
    </>
  )
}

export default ReviewDate
