import {
  cssTransition,
  toast,
  ToastContent,
  ToastOptions,
  Id,
} from 'react-toastify'

import styles from './Toast.module.scss'

import CloseIcon from 'assets/icons/icon-close.svg'

const CloseButton = ({ closeToast }: { closeToast: () => void }) => (
  <CloseIcon onClick={closeToast} title="close" />
)

export const openToast = (
  content: ToastContent,
  options?: ToastOptions,
): Id => {
  const toastId = options?.toastId || `${Date.now()}`
  // setTimeout is a workaround for issue
  // https://github.com/fkhadra/react-toastify/issues/343#issuecomment-1170893622
  setTimeout(() => {
    toast(content, {
      ...options,
      toastId,
      className: `toast ${options?.className}`,
      bodyClassName: `toastBody ${options?.bodyClassName}`,
      closeButton: CloseButton,
      transition:
        options?.transition ||
        cssTransition({
          enter: styles.slideInUp,
          exit: 'fadeOut',
        }),
      position: options?.position || 'bottom-left',
      closeOnClick: options?.closeOnClick || false,
    })
  }, 500)

  return toastId
}

export const dismissToastById = (id: Id) => {
  toast.dismiss(id)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  openToast,
  dismissToastById,
}
