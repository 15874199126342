import { useMemo } from 'react'
import dayjs from 'dayjs'

import type { BookingDates } from 'context/BookingContext'

import useBookingErrors from 'components/BookingQuote/hooks/useBookingErrors'

import { calculateTotalPriceForHit } from 'utils/Listings'

import { AlgoliaListing, AlgoliaListingFees } from 'types/externalData'

import { useSelect } from 'store'

type UseAvgPriceResponse = {
  avgPrice?: number
}

type UseAvgPriceProps = {
  avgPricePerNight?: number
  pricePerDay?: Record<string, number>
  bookingDates?: BookingDates
  fees?: AlgoliaListingFees
}

const useAvgPrice = ({
  pricePerDay,
  bookingDates,
  avgPricePerNight,
  fees,
}: UseAvgPriceProps): UseAvgPriceResponse => {
  const hasPetsSelected = useSelect((state) => state.search.selectedPets)
  const { start: checkIn, end: checkOut } = bookingDates || {}
  const { errors } = useBookingErrors()

  const avgPrice = useMemo(() => {
    if (!pricePerDay || !Object.keys(pricePerDay).length || !avgPricePerNight)
      return
    if (!checkIn || !checkOut || errors.dates) return avgPricePerNight

    const hit: Partial<AlgoliaListing> = {
      fees,
      PricePerDay: pricePerDay,
    }

    const stayLength = dayjs(checkOut).diff(dayjs(checkIn), 'days')

    return (
      Math.ceil(
        calculateTotalPriceForHit(
          hit,
          checkIn,
          checkOut,
          stayLength,
          hasPetsSelected,
        ),
      ) / stayLength
    )
  }, [pricePerDay, checkIn, checkOut, avgPricePerNight, errors])

  return {
    avgPrice,
  }
}

export default useAvgPrice
