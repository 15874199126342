import React, { useRef, useEffect } from 'react'
import Calendar, { CalendarProps, OnChangeDateCallback } from 'react-calendar'
import dayjs from 'dayjs'

export type CalendarDatePickerProps = {
  onChange?: OnChangeDateCallback
  dateDisabled?: CalendarProps['tileDisabled']
  activeStartDate: CalendarProps['activeStartDate']
  setActiveStartDate: (date: Date) => void
}

const handleViewChange = () => null
const handleActiveStartDateChange = () => null

const formatShortWeekday: CalendarProps['formatShortWeekday'] = (
  _locale,
  date,
) => dayjs(date).format('dd')

const CalendarDatePicker: React.FC<CalendarDatePickerProps> = ({
  onChange,
  dateDisabled,
  activeStartDate,
  setActiveStartDate,
}) => {
  const prevLabelRef = useRef<SVGSVGElement>(null)
  const nextLabelRef = useRef<SVGSVGElement>(null)

  const prevLabel = (
    <svg
      data-testid="prev-label"
      fill="#82888a"
      focusable="false"
      height={19}
      ref={prevLabelRef}
      viewBox="0 0 1000 1000"
    >
      <path d="M336 275L126 485h806c13 0 23 10 23 23s-10 23-23 23H126l210 210c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7L55 524c-11-11-11-21 0-32l249-249c21-22 53 10 32 32z"></path>
    </svg>
  )

  const nextLabel = (
    <svg
      data-testid="next-label"
      fill="#82888a"
      focusable="false"
      height={19}
      ref={nextLabelRef}
      viewBox="0 0 1000 1000"
    >
      <path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path>
    </svg>
  )

  // Attach event listeners to calendar navigation buttons
  useEffect(() => {
    const currentPrevLabelRef = prevLabelRef.current
    const currentNextLabelRef = nextLabelRef.current

    const handlePrevClick = () => {
      activeStartDate &&
        setActiveStartDate(dayjs(activeStartDate).subtract(1, 'month').toDate())
    }
    const handleNextClick = () => {
      activeStartDate &&
        setActiveStartDate(dayjs(activeStartDate).add(1, 'month').toDate())
    }
    currentPrevLabelRef?.parentElement?.addEventListener(
      'click',
      handlePrevClick,
    )
    currentNextLabelRef?.parentElement?.addEventListener(
      'click',
      handleNextClick,
    )

    return () => {
      currentPrevLabelRef?.parentElement?.removeEventListener(
        'click',
        handlePrevClick,
      )
      currentNextLabelRef?.parentElement?.removeEventListener(
        'click',
        handleNextClick,
      )
    }
  }, [activeStartDate, setActiveStartDate])

  return (
    <Calendar
      activeStartDate={activeStartDate}
      calendarType="US"
      formatShortWeekday={formatShortWeekday}
      next2Label={null}
      nextLabel={nextLabel}
      onActiveStartDateChange={handleActiveStartDateChange}
      onChange={onChange}
      onViewChange={handleViewChange}
      prev2Label={null}
      prevLabel={prevLabel}
      tileDisabled={dateDisabled}
      view="month"
    />
  )
}

export default CalendarDatePicker
