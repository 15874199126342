// Remove file depending on the winner after conclusion of DD-533
import React from 'react'

import { useSelect } from 'store/index'

import { selectIsMobile } from 'selectors/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'
import useAnalytics from 'hooks/useAnalytics/useAnalytics'

import style from './Navigation-ToCityLink.module.scss'

import NavigationArrow from 'assets/icons/icon-navigationArrow.svg'

type NavigationToCityLinkProps = {
  listing: any
  viewDetailsOpen: boolean
}

const NavigationToCityLink: React.FC<NavigationToCityLinkProps> = ({
  listing,
  viewDetailsOpen,
}) => {
  const { clickProductSection } = useAnalytics()

  const router = useAugmentedRouter()
  const isMobile = useSelect(selectIsMobile)

  if (isMobile && viewDetailsOpen) return null

  const country = listing?.Country === 'US' ? 'USA' : listing?.Country
  const { City: city } = listing
  const { state } = listing?.units[0]?.addresses[0]

  function onClick(e: React.MouseEvent<HTMLAnchorElement>) {
    const target = e.target as HTMLTextAreaElement
    e.preventDefault()
    router.push({
      pathname: '/vacation-rentals/search',
      query: {
        ...router.query,
        query: target.getAttribute('data-path'),
        prevListingPage: router.asPath.split('?')[0],
      },
    })
    clickProductSection({
      listingId: listing.objectID,
      section: 'Search Navigation',
    })
  }

  return (
    <div className={`${style.navigationByCityState}`}>
      {country && state && city && (
        <>
          <NavigationArrow height="30" width="30" />
          <a
            data-path={`${city}--${state}--${country}`}
            href="#"
            onClick={onClick}
          >
            View more {city} properties
          </a>
        </>
      )}
    </div>
  )
}

export default NavigationToCityLink
