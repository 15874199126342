import React from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'

// Redux
import { useSelect } from 'store/index'

// Config
import { LargeTabletBreakpoint } from 'config/Breakpoints'

const markerIcon = `<svg xmlns="http://www.w3.org/2000/svg"
xmlns:xlink="http://www.w3.org/1999/xlink" width="36px" height="48px" viewBox="0 0 36 48">
<defs>
    <filter id="a" width="272.7%" height="218.2%" x="-88.6%" y="-53%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5"/>
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.0352941176 0 0 0 0 0.247058824 0 0 0 0 0.270588235 0 0 0 0.5 0"/>
        <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="SourceGraphic"/>
        </feMerge>
    </filter>
    <filter id="b" width="186.9%" height="155.1%" x="-43.5%" y="-21.5%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5625" result="shadowSpreadOuter1"/>
        <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5"/>
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.0352941176 0 0 0 0 0.247058824 0 0 0 0 0.274509804 0 0 0 0.2 0"/>
    </filter>
    <path id="c" d="M21.38625 10.411875C21.38625 4.66155522 16.7246948 0 10.974375 0 5.22405522 0 .5625 4.66155522.5625 10.411875c0 0-.826875 6.868125 9.41625 21.9375.2235322.325017.5927224.5191814.9871875.5191814s.7636553-.1941644.9871875-.5191814c10.26-15.069375 9.433125-21.9375 9.433125-21.9375zm-10.411875 3.61125c-2.09695616 0-3.796875-1.6999188-3.796875-3.796875 0-2.09695616 1.69991884-3.796875 3.796875-3.796875 2.0969562 0 3.796875 1.69991884 3.796875 3.796875 0 1.0069939-.4000268 1.9727439-1.1120789 2.6847961-.7120522.7120521-1.6778022 1.1120789-2.6847961 1.1120789z"/>
</defs>
<g fill="none" fill-rule="nonzero" filter="url(#a)" transform="translate(7 4)">
    <use fill="#000" filter="url(#b)" xlink:href="#c"/>
    <use fill="#1BB2C5" stroke="#FFF" stroke-width="1.125" xlink:href="#c"/>
    <circle cx="10.974375" cy="10.22625" r="3.796875" fill="#FFF"/>
</g>
</svg>`

const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'poi.government',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

const mapContainerStyle = {
  height: '100%',
  width: '100%',
}

const options: google.maps.MapOptions = {
  zoomControlOptions: {
    position: 5,
  },
  mapTypeControl: false,
  styles: mapStyles,
  gestureHandling: 'greedy',
  fullscreenControl: true,
  streetViewControl: false,
  rotateControl: false,
  scrollwheel: false,
}

const mobileOptions: google.maps.MapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  styles: mapStyles,
  gestureHandling: 'greedy',
  fullscreenControl: true,
  streetViewControl: false,
  rotateControl: false,
  scrollwheel: false,
}

export type DetailMapProps = {
  location?: { lat: number; lng: number }
}

const DetailMap: React.FC<DetailMapProps> = ({ location }) => {
  // Redux Selectors
  const width = useSelect((state) => state.uiState.width)

  return location ? (
    <>
      <GoogleMap
        center={location}
        id="detailMap"
        mapContainerStyle={mapContainerStyle}
        options={
          width > LargeTabletBreakpoint ? options : (mobileOptions as any)
        }
        zoom={13}
      >
        <Marker
          icon={'data:image/svg+xml;charset=UTF-8;base64,' + btoa(markerIcon)}
          position={location}
        />
      </GoogleMap>
    </>
  ) : null
}

export default DetailMap
