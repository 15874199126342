import { memo } from 'react'
import classNames from 'classnames'

import styles from './ListingLocation.module.scss'

type ListingLocationProps = {
  city: string
  state: string
  country: string
  className?: string
}

const ListingLocation = ({
  city,
  state,
  country,
  className,
}: ListingLocationProps) => (
  <span className={classNames(styles.location, className)}>
    {city}, {state} {country !== 'US' && country}
  </span>
)

export default memo(ListingLocation)
