import React, { useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Image from 'next/image'
import dynamic from 'next/dynamic'

import { useDispatch, useSelect } from 'store/index'

import { toggleModal } from 'reducers/resultDetail'

import { useImageLoader } from 'hooks/useImageLoader'

import style from './Image-Gallery.module.scss'

import getListingCloudinaryId from 'utils/strings/getListingCloudinaryId'
import getCloudinaryPlaceholder from 'utils/strings/getCloudinaryPlaceholder'

import ComingSoon from 'assets/icons/Camera_Filled.svg'
import CameraIcon from 'assets/icons/icon-camera.svg'

// Dynamic Components
const ImageGalleryModal = dynamic(() => import('./Image-Gallery-Modal'))

type ImageGalleryProps = {
  images: any[]
  location: { lat: number; lng: number }
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images, location }) => {
  const [imageIndex, setImageIndex] = useState(0)
  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    align: 'start',
    containScroll: 'trimSnaps',
  })
  const [loadAllImages, setLoadAllImages] = useState(false)

  const imageLoader = useImageLoader()

  // Redux Selectors
  const openModal = useSelect((state) => state.resultDetail.openModal)
  // Redux Actions
  const appDispatch = useDispatch()

  const handleOpenModal = (index: number) => {
    setImageIndex(index)
    appDispatch(toggleModal())
  }
  // Prevent click events when slide is being dragged
  const onSlideClick = (slideIndex: number) => {
    if (embla?.clickAllowed()) {
      handleOpenModal(slideIndex)
    }
  }

  useEffect(() => {
    if (!embla) {
      return
    } else {
      setImageIndex(embla.selectedScrollSnap())

      embla.on('scroll', () => {
        setLoadAllImages(true)
        setImageIndex(embla.selectedScrollSnap())
      })
    }
  }, [embla])

  return (
    <div
      className={
        images && images.length ? style.gallery : style.galleryNoImages
      }
    >
      {openModal && (
        <ImageGalleryModal
          imageIndex={imageIndex}
          images={images}
          location={location}
        />
      )}

      {images && images.length ? (
        <>
          <button
            className={`${style.photoIndicator} imageGalleryTrigger`}
            id="photo_gallery_btn"
            onClick={() => handleOpenModal(imageIndex)}
          >
            <CameraIcon />
            <span className={style.photoTotalDesktop}>
              {`View ${images.length} photos`}
            </span>
            <span className={style.photoTotalTablet}>Photos</span>
            <span className={style.photoTotalMobile}>{`${
              imageIndex !== null && imageIndex + 1
            }/${images.length}`}</span>
          </button>
          <div className={style.embla}>
            <div className={style.embla__viewport} ref={viewportRef}>
              <div className={style.embla__container}>
                {images.map(
                  ({ URL, url, Caption, caption, alt: imageAltText }, i) => {
                    const altText =
                      imageAltText || Caption || caption || `Image ${i + 1}`

                    return (
                      <div
                        className={`${style.embla__slide} ${style.clickablePhoto} imageGalleryTrigger`}
                        key={i}
                        onClick={() => onSlideClick(i)}
                      >
                        <div className={style.embla__slide__inner}>
                          <div className={`image ${style.clickablePhoto}`}>
                            <Image
                              alt={altText}
                              blurDataURL={getCloudinaryPlaceholder(
                                getListingCloudinaryId(URL ?? url),
                              )}
                              className={style.clickablePhoto}
                              fill
                              loader={imageLoader}
                              loading={loadAllImages ? 'eager' : undefined}
                              onTouchStart={() => setLoadAllImages(true)}
                              placeholder={'blur'}
                              priority={i === 0 ? true : false}
                              sizes="(max-width: 600px) 100vw, (max-width: 1000px) calc(50w - 0.5rem), calc(45vw - 0.5rem)"
                              src={getListingCloudinaryId(URL ?? url)}
                              style={{
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  },
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={style.comingSoon}>
          <ComingSoon className={style.comingSoonImg} />
          <span>Coming Soon!</span>
        </div>
      )}
    </div>
  )
}

export default ImageGallery
