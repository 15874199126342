import { useMemo } from 'react'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'

import type { BookingDates } from 'context/BookingContext'

import type { AvailabilitySummary } from 'hooks/useListingAvailability'

type UseOverCapacityProps = {
  availability: AvailabilitySummary
  bookingDates: BookingDates
  maxDatesToCheckLength?: number
}

type UseOverCapacityResponse = {
  capacityBookedPercentage: number
  isOverCapacity: boolean
}

const BELOW_CAPACITY_THRESHOLD_PERCENTAGE = 50

const getBeforeCheckInDates = (
  maxDatesToCheckLength: number,
  dates: Array<string>,
  checkInDate: string,
): Array<string> => {
  const today = dayjs()
  const daysBetweenTodayAndCheckIn = dayjs(checkInDate).diff(today, 'days')
  const checkInIndex = dates.indexOf(checkInDate)
  if (daysBetweenTodayAndCheckIn) {
    const datesToCheckLength =
      daysBetweenTodayAndCheckIn > maxDatesToCheckLength
        ? maxDatesToCheckLength
        : maxDatesToCheckLength - daysBetweenTodayAndCheckIn

    if (checkInIndex < datesToCheckLength) {
      return dates.slice(0, checkInIndex)
    }
    return dates.slice(checkInIndex - datesToCheckLength, checkInIndex)
  }
  return []
}

const getAfterCheckOutDates = (
  maxDatesToCheckLength: number,
  dates: Array<string>,
  checkOutDate: string,
  beforeCheckInDatesLength: number,
): Array<string> => {
  const checkOutIndex = dates.indexOf(checkOutDate)
  const checkDatesAfterCheckOutLength =
    maxDatesToCheckLength + (maxDatesToCheckLength - beforeCheckInDatesLength)
  return dates.slice(
    checkOutIndex,
    checkOutIndex + checkDatesAfterCheckOutLength,
  )
}

const UseOverCapacity = ({
  availability,
  bookingDates,
  maxDatesToCheckLength = 15,
}: UseOverCapacityProps): UseOverCapacityResponse => {
  const { availabilityDatesMap } = availability
  const { start: checkIn, end: checkOut } = bookingDates || {}

  const capacityBookedPercentage = useMemo(() => {
    if (!checkIn || !checkOut || isEmpty(availabilityDatesMap)) {
      return 0
    }

    const availabilityDatesKeys = Object.keys(availabilityDatesMap)
    let datesToCheckAvailability = getBeforeCheckInDates(
      maxDatesToCheckLength,
      availabilityDatesKeys,
      checkIn,
    )
    datesToCheckAvailability = [
      ...datesToCheckAvailability,
      ...getAfterCheckOutDates(
        maxDatesToCheckLength,
        availabilityDatesKeys,
        checkOut,
        datesToCheckAvailability.length,
      ),
    ]
    const bookedLength = datesToCheckAvailability.filter(
      (date) => !availabilityDatesMap[date].available,
    ).length
    return Math.round((100 / datesToCheckAvailability.length) * bookedLength)
  }, [availabilityDatesMap, checkIn, checkOut, maxDatesToCheckLength])

  return {
    capacityBookedPercentage,
    isOverCapacity:
      capacityBookedPercentage >= BELOW_CAPACITY_THRESHOLD_PERCENTAGE,
  }
}

export default UseOverCapacity
