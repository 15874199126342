import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  memo,
  Children,
} from 'react'
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react'
import classNames from 'classnames'

import styles from './BaseCarousel.module.scss'
import PageIndicator from './PageIndicator'
import { PrevButton, NextButton } from './NavControls'

// TODO: Add render prop to customize Buttons and PageIndicator
type BaseCarouselProps = EmblaOptionsType & {
  children: React.ReactNode
  slideClassName?: string
  pagination?: boolean
  navControls?: boolean
  navControlsAutoHide?: boolean
  onScroll?: (activeSlide: number, toNext: boolean) => void
}

const BaseCarouselConfig: Partial<EmblaOptionsType> = {
  align: 'start',
  speed: 20,
}

const BaseCarousel = ({
  slideClassName,
  children,
  pagination,
  navControls = true,
  navControlsAutoHide = false,
  onScroll,
  ...props
}: BaseCarouselProps) => {
  const slidesContent = useMemo(() => Children.toArray(children), [children])
  const [pages, setPages] = useState<number[]>([])
  const [emblaRef, emblaAPI] = useEmblaCarousel({
    ...BaseCarouselConfig,
    ...props,
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [activePage, setActivePage] = useState(0)

  const scrollTo = useCallback(
    (next?) => {
      if (next) {
        emblaAPI?.scrollNext()
      } else {
        emblaAPI?.scrollPrev()
      }
    },
    [emblaAPI],
  )

  const onScrollHandler = useCallback(
    (onScrollArg?: string) => {
      if (!emblaAPI || !onScroll || !onScrollArg) return
      const prevSelectPage = emblaAPI!.previousScrollSnap()
      const selectPage = emblaAPI!.selectedScrollSnap()
      onScroll!(activePage, selectPage > prevSelectPage)
    },
    [activePage, emblaAPI, onScroll],
  )

  useEffect(() => {
    if (!emblaAPI) return
    setPages(emblaAPI.scrollSnapList())

    const onSelect = (arg?: string) => {
      if (!emblaAPI) return
      onScrollHandler(arg)
      setActivePage(emblaAPI.selectedScrollSnap())
      setPrevBtnEnabled(emblaAPI.canScrollPrev())
      setNextBtnEnabled(emblaAPI.canScrollNext())
    }
    const updateState = () => {
      if (!emblaAPI) return
      setPages(emblaAPI.scrollSnapList())
      onSelect()
    }
    updateState()
    emblaAPI.on('select', onSelect)
    emblaAPI.on('init', updateState)
    emblaAPI.on('reInit', updateState)
  }, [emblaAPI])

  useEffect(() => {
    emblaAPI?.reInit()
    emblaAPI?.scrollTo(0)
  }, [slidesContent, emblaAPI])

  return (
    <div className={classNames(styles.main, styles.embla)}>
      <div className={styles.embla__viewport} ref={emblaRef}>
        <div
          className={classNames('carousel__container', styles.embla__container)}
        >
          {slidesContent?.map((slide, i) => (
            <div className={slideClassName ?? styles.embla__slide} key={i}>
              <div>{slide}</div>
            </div>
          ))}
        </div>
      </div>
      {navControls && (
        <>
          <PrevButton
            autoHide={navControlsAutoHide}
            enabled={prevBtnEnabled}
            onClick={() => scrollTo()}
          />
          <NextButton
            autoHide={navControlsAutoHide}
            enabled={nextBtnEnabled}
            onClick={() => scrollTo(true)}
          />
        </>
      )}
      {pagination && <PageIndicator activePage={activePage} pages={pages} />}
    </div>
  )
}

export default memo(BaseCarousel)
