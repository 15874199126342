import { memo } from 'react'
import classNames from 'classnames'
import uniqueId from 'lodash/uniqueId'

import StarRatings from 'components/StarRatings/StarRatings'

import styles from './ListingRating.module.scss'

type ListingRatingProps = {
  id?: string
  className?: string
  reviews: number
  rating: number
  starsStyle?: Partial<{
    starDimension: string
    starSpacing: string
    starRatedColor: string
  }>
}

const starsStyleDefauts: Partial<ListingRatingProps['starsStyle']> = {
  starDimension: '14px',
  starRatedColor: '#F9A01F',
  starSpacing: '1px',
}

const ListingRating = ({
  rating,
  reviews,
  className,
  starsStyle = starsStyleDefauts,
}: ListingRatingProps) => {
  if (!rating && !reviews) {
    return null
  }

  return (
    <div className={classNames(styles.rating, className)}>
      {rating && (
        <>
          <span className="bold">{rating}</span>
          <StarRatings rating={rating} uniqueId={uniqueId()} {...starsStyle} />
        </>
      )}
      {reviews && <span className={styles.reviews}>({reviews})</span>}
    </div>
  )
}

export default memo(ListingRating)
