import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import CustomLink from 'components/Link/CustomLink'

import style from './Share-Modal.module.scss'

import { buildSocialShareLinks } from 'utils/Social'
import type { CombinedListing } from 'utils/staticData'

import CloseIcon from 'assets/icons/icon-close.svg'
import TwitterShareIcon from 'assets/icons/icon-twitter-simple.svg'
import FacebookShareIcon from 'assets/icons/icon-facebook-simple.svg'
import PinterestShareIcon from 'assets/icons/icon-pintrest-simple.svg'
import EmailShareIcon from 'assets/icons/icon-email-simple.svg'
import LinkIcon from 'assets/icons/icon-link.svg'

type ShareModalProps = {
  setShareModalOpen: (...args: any[]) => void
  styleGuide: boolean
  listing: Partial<CombinedListing>
}

const ShareModal: React.FC<ShareModalProps> = ({
  setShareModalOpen,
  styleGuide,
  listing,
}) => {
  const [copied, setCopied] = useState('')
  const shareRef = useRef<HTMLDivElement>(null)
  const router = useAugmentedRouter()
  const [shared, setShared] = useState(false)

  const url = new URL(process.env.NEXT_PUBLIC_ORIGIN + router.asPath)

  url.searchParams.delete('queryID')
  url.searchParams.delete('utm_source')
  url.searchParams.delete('utm_campaign')
  url.searchParams.delete('utm_medium')

  url.searchParams.append('utm_source', 'referral')
  url.searchParams.append('utm_campaign', 'listingshare')

  const shareUrl = decodeURIComponent(url.toString())

  const handleShareClickEvent = useCallback(
    (sharedVia?: string) => {
      if (!sharedVia && shared) {
        return
      }
      setShared(true)
      window.analytics.track('Product Shared', {
        product_id: listing.objectID,
        share_via: sharedVia || '',
      })
    },
    [shared, listing.objectID],
  )

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (shareRef.current && !shareRef.current.contains(e.target)) {
        setShareModalOpen(false)
        handleShareClickEvent()
        document.body.classList.remove('noScroll')
      }
    }
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [handleShareClickEvent, setShareModalOpen])

  const { email } = buildSocialShareLinks(shareUrl)

  const getHeadline = () => {
    const headline = listing['adContent'].filter(
      ({ type }) => type === 'headline',
    )
    return headline[0]['text']
  }

  const copyToClipboard = async (e) => {
    e.preventDefault()

    await navigator.clipboard.writeText(`${shareUrl}&utm_medium=link`)

    setCopied('Copied!')
    handleShareClickEvent('Copy Link')

    setTimeout(() => {
      setCopied('')
    }, 1500)
  }

  const closeShareModal = () => {
    setShareModalOpen(false)
    handleShareClickEvent()
    document.body.classList.remove('noScroll')
  }

  return (
    <div
      className={
        styleGuide ? style.shareModalNoWrapper : style.shareModalWrapper
      }
    >
      <div
        className={styleGuide ? style.shareModalNo : style.shareModal}
        ref={shareRef}
      >
        <div className={style.shareModalBody}>
          {!styleGuide && (
            <div className={style.shareModalTop}>
              <button
                className={`${style.modalClose} ${style.responsiveCloseIcon}`}
                onClick={() => closeShareModal()}
              >
                <CloseIcon width={11} />
              </button>
            </div>
          )}
          <div className={style.shareModalMain}>
            <span className={style.shareModalTitle}>Share this Property</span>
            {listing && listing['units'] && (
              <Fragment>
                <img
                  alt=""
                  className={style.shareModalImg}
                  src={listing['units'][0]['images'][0]['url']}
                />
                <span className={style.shareModalHeadline}>
                  {getHeadline()}
                </span>
                <span className={style.shareModalLocation}>
                  {listing['units'][0]['addresses'][0]['city']}
                  ,&nbsp;
                  {listing['units'][0]['addresses'][0]['stateShort']}
                  &nbsp;
                  {listing['units'][0]['addresses'][0]['countryShort'] !==
                    'US' && listing['units'][0]['addresses'][0]['country']}
                </span>
              </Fragment>
            )}
          </div>
          <div className={style.shareModalBottom}>
            <FacebookShareButton
              beforeOnClick={() => handleShareClickEvent('Facebook')}
              className={style.shareLinks}
              data-testid="share_listing_facebook_btn"
              id="share_listing_facebook_btn"
              title="Share this post on Facebook"
              url={`${shareUrl}&utm_medium=facebook`}
            >
              <FacebookShareIcon />
              <span>Facebook</span>
            </FacebookShareButton>
            <TwitterShareButton
              beforeOnClick={() => handleShareClickEvent('Twitter')}
              className={`${style.shareLinks} ${style.twitter}`}
              data-testid="share_listing_twitter_btn"
              id="share_listing_twitter_btn"
              // title="Share this post on Twitter"
              url={`${shareUrl}&utm_medium=twitter`}
              via="EvolveVR"
            >
              <TwitterShareIcon width={38} />
              <span>Twitter</span>
            </TwitterShareButton>
            <PinterestShareButton
              beforeOnClick={() => handleShareClickEvent('Pinterest')}
              className={style.shareLinks}
              data-testid="share_listing_pinterest_btn"
              id="share_listing_pinterest_btn"
              media={
                listing &&
                listing['units'] &&
                listing['units'][0]['images'][0]['url']
              }
              title="Share this post on Pinterest"
              url={`${shareUrl}&utm_medium=pinterest`}
            >
              <PinterestShareIcon width={38} />
              <span>Pinterest</span>
            </PinterestShareButton>
            <a
              className={style.shareLinks}
              data-testid="share_listing_clipboard_btn"
              href={email}
              id="share_listing_email_btn"
              onClick={() => handleShareClickEvent('Email')}
              rel="noopener noreferrer"
              target="_blank"
              title="Share this post with Email"
            >
              <EmailShareIcon width={38} />
              <span>Email</span>
            </a>
            <CustomLink
              className={style.shareLinks}
              href="/"
              id="share_listing_clipboard_btn"
              onClick={copyToClipboard}
              rel="noopener noreferrer"
              target="_blank"
              title="Copy this Url"
            >
              <LinkIcon width={38} />
              <span>Copy Link</span>
              {copied !== '' && (
                <span className={style.success}>&nbsp;&nbsp;{copied}</span>
              )}
            </CustomLink>
          </div>
          <button
            className={`btn btn-secondary ${style.cancelBtn}`}
            id="close_share_modal"
            onClick={() => closeShareModal()}
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default ShareModal
