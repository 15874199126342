import { SearchState } from 'react-instantsearch-core'

import { AppState } from 'store/index'

import getLOSNights from 'utils/strings/getLOSNights'

const getSearchStateFromAppState = (
  state: Partial<AppState['search']>,
): SearchState => {
  const {
    query,
    selectedMinBeds,
    selectedMinBathrooms,
    selectedMinBedrooms,
    selectedDates,
    selectedMinPrice,
    selectedMaxPrice,
    selectedAmenities,
    selectedLocationAmenities,
    selectedPropertyTypes,
    selectedAccessibility,
    selectedViewAmenities,
  } = state
  const range: any = {}
  const refinementList: SearchState['refinementList'] = {}

  if (selectedMinBeds) {
    range['Total Beds'] = {
      min: selectedMinBeds,
      max: undefined,
    }
  }
  if (selectedMinBathrooms) {
    range['Bathrooms'] = {
      min: selectedMinBathrooms,
      max: undefined,
    }
  }
  if (selectedMinBedrooms) {
    range['Bedrooms'] = {
      min: selectedMinBedrooms,
      max: undefined,
    }
  }
  if (
    !selectedDates?.start &&
    !selectedDates?.end &&
    (selectedMinPrice || (selectedMaxPrice && selectedMaxPrice < 1000))
  ) {
    range['Average Per Night'] = {
      min: selectedMinPrice ?? undefined,
      max:
        selectedMaxPrice && selectedMaxPrice < 1000
          ? selectedMaxPrice
          : undefined,
    }
  }
  if (
    selectedDates?.start &&
    selectedDates?.end &&
    (selectedMinPrice || (selectedMaxPrice && selectedMaxPrice < 1000))
  ) {
    const nights = getLOSNights(selectedDates?.start, selectedDates?.end)
    range[`LOS_PriceAverages.${selectedDates?.start}.${nights}`] = {
      min: selectedMinPrice ?? undefined,
      max:
        selectedMaxPrice && selectedMaxPrice < 1000
          ? selectedMaxPrice
          : undefined,
    }
  }

  if (selectedAmenities && selectedAmenities.length) {
    refinementList['amenities.Amenities'] = selectedAmenities
  }
  if (selectedLocationAmenities && selectedLocationAmenities.length) {
    refinementList['amenities.Location'] = selectedLocationAmenities
  }
  if (selectedViewAmenities && selectedViewAmenities.length) {
    refinementList['amenities.View'] = selectedViewAmenities
  }
  if (selectedPropertyTypes && selectedPropertyTypes.length) {
    refinementList['Property Type'] = selectedPropertyTypes
  }
  if (selectedAccessibility && selectedAccessibility.length) {
    refinementList['amenities.Accessibility'] = selectedAccessibility
  }

  return {
    query,
    range,
    refinementList: refinementList,
  }
}

export default getSearchStateFromAppState
