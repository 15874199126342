import React, { useEffect, useState } from 'react'

import SeeMoreButton from './SeeMoreButton'
import Bedroom from './Bedroom'
import style from './Sleeping-Arrangements.module.scss'

import type { CombinedListing } from 'utils/staticData'

import type { BoomiListing } from 'types/externalData'

type BedroomData = BoomiListing['bedrooms']

type SleepingArrangementsProps = {
  listing: CombinedListing
  detailMenuRefs: React.MutableRefObject<Record<string, HTMLElement | null>>
}

const SleepingArrangements: React.FC<SleepingArrangementsProps> = ({
  listing,
  detailMenuRefs,
}) => {
  const [orderedBedrooms, setOrderedBedrooms] = useState<BedroomData[] | null>(
    null,
  )
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const getBeds = () => {
      let bedrooms: BedroomData[] = listing?.units[0].bedrooms

      // filter out rooms that don't have any beds
      bedrooms = bedrooms.filter((bedroom: BedroomData) => {
        let roomHasBeds = false

        for (let i = 0; i < bedroom.beds.length; i++) {
          const bed = bedroom.beds[i]

          if (bed.number > 0) {
            roomHasBeds = true
            break
          }
        }

        return roomHasBeds
      })

      // sort rooms by master > bedroom > living room > anything else
      const order: { [key: string]: number } = {
        'bedroom': 1,
        'living room': 2,
        'default': Infinity,
      }
      bedrooms = bedrooms.sort((a: BedroomData, b: BedroomData) => {
        return (
          (order[a.type.toLowerCase()] || order.default) -
          (order[b.type.toLowerCase()] || order.default)
        )
      })

      // sort rooms alphanumerically by name
      bedrooms = bedrooms.sort((a: BedroomData, b: BedroomData) => {
        const aName = a.name.toLowerCase()
        const bName = b.name.toLowerCase()

        const aIsMaster = aName.indexOf('master') > -1
        if (aIsMaster) {
          return -1
        }

        const bIsMaster = bName.indexOf('master') > -1
        if (bIsMaster) {
          return 1
        }

        const aHasNumber = aName.match(/(\d+)/g)
        if (!aHasNumber || !aHasNumber.length) {
          return 1
        }

        const bHasNumber = bName.match(/(\d+)/g)
        if (!bHasNumber || !bHasNumber.length) {
          return -1
        }

        return (
          Number(aName.match(/(\d+)/g)![0]) - Number(bName.match(/(\d+)/g)![0])
        )
      })

      return bedrooms
    }

    if (
      listing &&
      'units' in listing &&
      listing.units.length &&
      !orderedBedrooms
    ) {
      setOrderedBedrooms(getBeds())
    }
  }, [listing, orderedBedrooms])

  return orderedBedrooms ? (
    <div
      className={style.sleepingArrangements}
      id="sleep-arrangements"
      ref={(el) => (detailMenuRefs.current['sleep-arrangements'] = el)}
    >
      <h2 className={`section-subtitle`}>Sleeping Arrangements</h2>
      <div className={style.sleepingGrid}>
        {orderedBedrooms
          // Show 4 bedrooms by default, toggle state.open to show all
          .slice(0, open ? orderedBedrooms.length : 4)
          .map((bedroom: BedroomData) => (
            <Bedroom bedroom={bedroom} key={bedroom.name} />
          ))}
      </div>
      {orderedBedrooms.length > 4 && (
        <SeeMoreButton
          id="sleeping_arrangements_see_more_btn"
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  ) : null
}

export default SleepingArrangements
